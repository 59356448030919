import React, {useEffect, useState} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';

import {GetVeranstaltungFormat} from "../helperFunctions/helperFunctionsVeranstaltung";
import {GetChorDescription,GetChorName,GetChorImage,GetChorLink,GetChorLand} from "../helperFunctions/helperFunctionsChor"

import LikedVeranstaltungen from './likedVeranstaltungen';
import Header from '../components/header';
import Footer from '../components/footer';
import Headline from '../elements/headline';
import Skyline from '../grafiken/skyline.png';

const LikedVeranstaltungenPage = ({props}) => {

    const {id} = useParams();
    const {state: {veranstaltungen}} = useContent();
    const [veranstaltungFilter,setVeranstalungFilter]=useState(0);
    const [activeFormat, setActiveFormat] = useState([0,0,0,0,0]);
    const [alleFormate, setFormate]=useState([]);

    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }

        useEffect(()=>{
          if(veranstaltungen)
          {
            let formate=[];
            veranstaltungen.forEach(e => {
              
              if(!formate.includes(GetVeranstaltungFormat(e)))
              {
                formate.push(GetVeranstaltungFormat(e));
              }
              
            });
            setFormate(formate);
          }
        },[veranstaltungen])

      function setFormat(index){
          let formate=[0,0,0,0,0];
          formate[index]=!activeFormat[index];
          setActiveFormat(formate);
      }

      useEffect(()=>{
        if (veranstaltungen) {

          let filterveranstaltungen=[];
          let activeFilter=false;
          veranstaltungen.forEach((e)=>{
            if(GetVeranstaltungFormat(e)===alleFormate[activeFormat.findIndex((e) => e === true)])
            {
              activeFilter=true;
              filterveranstaltungen.push(e);
            }
          })

          if(activeFilter)setVeranstalungFilter(filterveranstaltungen);
          else setVeranstalungFilter(veranstaltungen);
        }
      },[activeFormat,veranstaltungen])


    return (  

        <React.Fragment>

            <Header/>
            <div className="w-full h-full justify-center bg-yellow">

            <div className="relative w-full h-52 flex items-center justify-center bg-yellow">

<img
  src={Skyline} 
  alt="Background Image"
  className="absolute inset-0 w-full h-full object-cover z-0"
/>
<div className="relative z-10 px-4 mb-12 h-full text-center text-6xl pt-28 text-gray font-roboto font-bold break-words">

  </div>


</div>

            

{/** Stadt oder Region Filter
        <div className=" bg-white w-full h-full sticky z-20 top-0">
      <div className="h-full text-center text-lg px-20 py-4 text-gray font-light font-roboto justify-center flex ">
        <div className='w-64'>
          <label className="cursor-pointer label">
            <span className="label-text font-bold text-lg">Stadt</span> 
              <input id="stadt" type="radio" name="radio-region" value={"stadt"} className="radio radio-info radio-lg -mr-1" checked={!isMapRegion} onClick={(e)=>{setIsMapRegion(0)}}/>
              <input id="region" type="radio" name="radio-region" value={"region"} className="radio radio-accent radio-lg -ml-1" checked={isMapRegion} onClick={(e)=>{setIsMapRegion(1)}}/>
            <span className="label-text font-bold text-lg ">Region</span> 
          </label>
        </div>
      </div>
    </div>
    */}
<div className="bg-white h-full text-center text-lg px-0 md:px-10 lg:px-20 py-4 text-gray font-normal font-roboto justify-center flex ">
      <div className="relative w-full md:w-3/4 z-10  h-full text-left text-3xl pt-3 text-gray font-roboto font-bold break-words">
        <span className='px-6'>
        Deine Favoriten
      </span>
    </div>
  </div>
<div className="bg-white bg-opacity-100 h-max w-full justify-center items-center flex relative z-30 pb-4" >

<LikedVeranstaltungen width="w-full md:w-2/3 " height="h-max" />
       
                </div>
 
</div>

{/** 
<div className="bg-white bg-opacity-100 overflow-hidden h-max w-full justify-center items-center flex relative z-30 py-8" >
          <VeranstaltungenListe width="w-full md:w-3/5 xl:w-2/3 2xl:w-2/3" height="h-max" veranstaltungen={veranstaltungFilter} />
    </div>
    */}
    <Footer/>
        </React.Fragment>
    );
}

export default LikedVeranstaltungenPage;