import React, {useEffect, useState, useContext} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import {GetOrtName,GetOrtBild,GetOrtBeschreibung,GetOrtKoordinaten} from "../helperFunctions/helperFunctionsVeranstaltungsOrt";
import {GetVeranstaltungDatum,GetVeranstaltungTicketLink,IsVeranstaltungCity,GetVeranstaltungName,GetVeranstaltungOrt,GetVeranstaltungZeit,GetVeranstaltungDatumFormatA,GetVeranstaltungLink} from "../helperFunctions/helperFunctionsVeranstaltung";
import {GetChorDescription,GetChorName,GetChorImage,GetChorLink,GetChorLand} from "../helperFunctions/helperFunctionsChor";
import { textFormat } from '../helperFunctions/helperFunctionsTextFormat.js';
import Navbar from './navbar';
import NavigationElement from '../elements/navigationElement';
import Header from '../components/header';
import Footer from '../components/footer';
import VeranstaltungTermin from '../elements/VeranstaltungTermin';
import Paragraph from '../elements/paragraph';
import Headline from '../elements/headline';
import SubHeadline from '../elements/subHeadline';
import Map from './map.js';
import { LanguageContext } from '../hooks/languageContext';


const VeranstaltungOrtDetails = ({props}) => {

    const {id} = useParams();
    const {state: {veranstaltungsorte}} = useContent();
    const {state: {veranstaltungen}} = useContent();
    const [veranstaltungsOrt,setVeranstalungsOrt]=useState(0);
    const [ortIndex,setOrtIndex]=useState(0);
    const [ort,setOrt]=useState(0);
    const [language, setLanguage] = useContext(LanguageContext);
    const [veranstaltungenOrt,setVeranstaltungenOrt]=useState(0);
    const [mapOrt, setMaport] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); 


    function GetOrtByLink(linkid)
    {
        var ortfound=null;
        for(let i=0; i<veranstaltungsorte.length;i++)
        {
          if(veranstaltungsorte[i].link.current===linkid)
          {
            ortfound= veranstaltungsorte[i];
            setOrtIndex(i);
            break;
          }
        }

        return ortfound;
    }

    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }

    useEffect(()=>{
        if(veranstaltungsorte && id) 
          {
            let activeOrt=GetVeranstaltungsortByLink(id);
            setVeranstalungsOrt(activeOrt);
          }
    },[veranstaltungsorte, id])

    useEffect(()=>{
      if(veranstaltungsorte && id && veranstaltungen) 
        {

          let filterVeranstaltungenOrt=[];
          let activeOrt=GetOrtByLink(id);
          veranstaltungen.forEach(veranstaltung => {
            
            if(veranstaltung.veranstaltungsort)
            {
              veranstaltung.veranstaltungsort.forEach(ort=>{
                
                if(ort._id===activeOrt._id)
                {

                  filterVeranstaltungenOrt.push(veranstaltung);
                }
              })
            }
          });

          setVeranstaltungenOrt(filterVeranstaltungenOrt);
          setOrt(activeOrt);
        }
  },[veranstaltungsorte,id,veranstaltungen])


    function GetVeranstaltungsortByLink(linkid)
    {
        var veranstaltungsortfound=null;
        for(let i=0; i<veranstaltungsorte.length;i++)
        {
          if(veranstaltungsorte[i].link.current===linkid)
          {
            veranstaltungsortfound= veranstaltungsorte[i];
            setOrtIndex(i);
              break;
          }
        }
        return veranstaltungsortfound;
    }


    function GetNextOrt(){
        let index=0;
        if (ortIndex<veranstaltungsorte.length-1)index=ortIndex+1;
        return veranstaltungsorte[index].link.current;
      }
  
      function GetPrevOrt(){
        let index=veranstaltungsorte.length-1;
        if (ortIndex>0)index=ortIndex-1;
        return veranstaltungsorte[index].link.current;
      }

      function isSameDate(veranstaltung,index){
        if(index===0) return false;
        if(GetVeranstaltungDatumFormatA(veranstaltungenOrt[index - 1]) === GetVeranstaltungDatumFormatA(veranstaltung))
        return true;
        else return false;
      }

    return (  

        <React.Fragment>

            <Header/>
            {veranstaltungsOrt &&


<div className='bg-yellow'>
<img className="h-2/6   w-full object-cover "
            alt={GetOrtName(veranstaltungsOrt)}
            src={GetOrtBild(veranstaltungsOrt,1800,600)}>
            </img>

            <NavigationElement 
            next={"../veranstaltungsorte/"+GetNextOrt()}
            prev={"../veranstaltungsorte/"+GetPrevOrt()}
            name={GetOrtName(veranstaltungsOrt)}
            />
            </div>

        }
                 <div className="bg-yellow bg-opacity-100 h-max w-full  justify-center items-center flex relative z-30 pt-8 pb-16 mb-8" >
                 <div className="px-1 md:px-4 lg:px-20 mx-auto text-justify">
            <Paragraph>
              <PortableText value={GetOrtBeschreibung(veranstaltungsOrt, language)}
              components={textFormat}/>
            </Paragraph>
            </div>
</div>

<div className="justify-center mt-2 md:mt-12 lg:mt-18 mb-6 
                        text-center text-2xl lg:text-6xl text-gray font-roboto font-bold">
          <div className={"w-full md:w-4/6 h-max card bg-white max-h-max ml-auto mr-auto shadow-xl z-20"}>

            <div className="card-body h-full ">
              <h2 className="card-title ">{language ==="en"? <SubHeadline>On-site events</SubHeadline>:<SubHeadline>Veranstaltungen vor Ort</SubHeadline>}</h2>
              <div className='h-full overflow-y-auto pointer-events-auto'>

                <table className="table table-sm ">


                  {veranstaltungenOrt && veranstaltungenOrt.map((veranstaltung, index) => (

                    <VeranstaltungTermin ticketlink={GetVeranstaltungTicketLink(veranstaltung)} screenWidth={screenWidth} name={GetVeranstaltungName(veranstaltung,"", true)} zeit={GetVeranstaltungZeit(veranstaltung)} ort={GetVeranstaltungOrt(veranstaltung)} id={veranstaltung._id} datum={GetVeranstaltungDatumFormatA(veranstaltung)} newDate={isSameDate(veranstaltung, index)} veranstaltung={veranstaltung} link={GetVeranstaltungLink(veranstaltung)} stadt={IsVeranstaltungCity(veranstaltung)} />

                  ))}
                </table>
              </div>
            </div>
          </div>
</div>
{GetOrtKoordinaten(veranstaltungsOrt) &&
             <div className="h-full mt-8 md:mt-16 text-center text-lg px-6 pt-4 pb-12 text-gray font-light font-roboto w-full lg:w-4/6">
                    <span>{language==="de"?<span>Mit Google Maps zum Ort navigieren? </span>:<span>Navigate to the location with Google Maps?</span>}</span>
                <a className='font-bold' href={'https://maps.google.com/?q='+GetOrtKoordinaten(veranstaltungsOrt).lat+','+GetOrtKoordinaten(veranstaltungsOrt).lng}> Google navigation link</a>
              </div>
            }
<div className="w-full h-96  z-0 top-0 md:relative  overflow-hidden">
              {GetOrtKoordinaten(veranstaltungsOrt) &&
            <Map region={{"center":[GetOrtKoordinaten(veranstaltungsOrt).lng,GetOrtKoordinaten(veranstaltungsOrt).lat],
                          "zoom":12}} 
                        setOrt={setMaport} 
                        activeMarker={GetOrtKoordinaten(veranstaltungsOrt)}
                        oneLocationOnly={true}/>
               }
            </div>
        <Footer/>
        </React.Fragment>
    );
}

export default VeranstaltungOrtDetails;