import React, {useContext,useEffect} from 'react';
import LikeButton from './likeButton';
import { Link } from "react-router-dom";
import { GetVeranstaltungOrtLink, GetVeranstaltungDatum, GetVeranstaltungName, GetVeranstaltungDatumFormatA, GetVeranstaltungZeit } from "../helperFunctions/helperFunctionsVeranstaltung";
import { GetChorName, GetLink } from '../helperFunctions/helperFunctionsChor';
import TableLine from './tableLine';
function VeranstaltungTerminSmall(props)  {


    return(
        <React.Fragment key={props.id}>

                {props.newDate ?
                  null :
                  <thead className='sticky top-0  z-30'>

                    <tr className=''>
                      <td className='bg-white text-sm font-roboto text-gray' ><br/>{props.datum}</td>
                    </tr>
                  </thead>
                }

                <tbody>
                  <tr className=''>
                    <td className=' font-roboto'><span className='p-1'></span>{props.zeit}</td> 
                    <td className='w-32 h-32 font-roboto'>
                    {props.format=="Get together"?
                    
                    <Link to={"../veranstaltungen/"+props.link}><td >{props.name}</td>
                    </Link>
                    :
                    <Link to={"../veranstaltungen/"+props.link}><td >{props.name}</td>
                    </Link>
                    }
                    </td>
                    <td>
                      

                    </td>
                    <td>
                      <LikeButton id={props.id} />
                    </td>
                  </tr>
                </tbody>
              </React.Fragment>
    )


}

export default VeranstaltungTerminSmall;