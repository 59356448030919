import React, {useEffect, useState, useContext} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import NavigationElement from '../elements/navigationElement';
import {GetFooterText, GetFooterName,GetFiles} from "../helperFunctions/helperFunctionsFooter.js"
import Header from '../components/header';
import Footer from '../components/footer';
import Headline from '../elements/headline.js';
import Paragraph from '../elements/paragraph.js';
import Skyline from '../grafiken/skyline.png';
import Newsletter from './newsletter.js';

import { LanguageContext } from '../hooks/languageContext';

const FooterDetailsNewsletter = ({props}) => {

    const {id} = useParams();
    const {state: {footer}} = useContent();
    const [footerContent,setFooterContent] =useState(null);
    const [downloads,setDownloads] =useState(null);
    const [footerIndex,setFooterIndex]=useState(0);
    const [language, setLanguage] = useContext(LanguageContext);


    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }

      useEffect(()=>{
          window.scrollTo(0, 0);
      },[])


      useEffect(()=>{
        if(footer && id) 
          {
            let activeFooter=GetFooterbyLink(id);
            console.log("FOOTER");
            console.log(activeFooter)
            setDownloads(GetFiles(activeFooter))
            setFooterContent(activeFooter);
          }
    },[footer, id])

      function GetNextFormat(){
        let index=0;
        if (footerIndex<footer.length-1)index=footerIndex+1;
        return footer[index].link.current;
      }
  
      function GetPrevFormat(){
        let index=footer.length-1;
        if (footerIndex>0)index=footerIndex-1;
        return footer[index].link.current;
      }

      useEffect(()=>{
        if(footer)
        {
          GetFooterbyLink(id);
        }
      },[footer])

      function GetFooterbyLink(footerid)
    {
        var footerFound=null;
        for(let i=0; i<footer.length;i++)
        {
          if(footer[i].link.current===footerid)
          {
            footerFound = footer[i];
            setFooterIndex(i)
            console.log(footerFound);
            setFooterContent(footerFound);
              break;
          }
        }
        return footerFound;
    }
 
  
  const components = {
    block: ({ node, children }) => {
      if (node.style === 'hervorgehoben') {
        return <p className="min-h-6 text-lg font-roboto font-bold">{children}</p>;
      }
      
      // Default style for 'normal'
      return <p className="min-h-6 text-lg font-roboto font-normal">{children}</p>;
    },
  };

  const serializers = {
    types: {
      block: (props) => {
        const style = props.node.style || "normal";
  
        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "");
          return React.createElement(
            `h${level}`,
            { className: "text-lg" },
            props.children
          );
        }
  
        return style === "blockquote" ? (
          <blockquote>– {props.children}</blockquote>
        ) : (
          <p>{props.children}</p>
        );
      },
      code: (props) =>
        console.log("code block", props) || (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        ),
      youtube: (props) => <pre>{JSON.stringify(props, null, 2)}</pre>
    },
    list: (props) =>
      console.log("list", props) ||
      (props.type === "bullet" ? (
        <ul className="list-disc list-outside">{props.children}</ul>
      ) : (
        <ol className="list-decimal list-outside">{props.children}</ol>
      )),
    listItem: (props) =>
      console.log("list", props) ||
      (props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>{props.children}</li>
      )),
    marks: {
      strong: (props) =>
        console.log("strong", props) || <strong>{props.children}</strong>,
      em: (props) => console.log("em", props) || <em>{props.children}</em>,
      code: (props) => console.log("code", props) || <code>{props.children}</code>
    }
  };

  


    return (  

        <React.Fragment>

            <Header/>
            

            <div className="relative w-full h-52 flex items-center justify-center bg-yellow">

  <img
    src={Skyline} 
    alt="Background Image"
    className="absolute inset-0 w-full h-full object-cover z-0"
  />


  
</div>

     <div>       
     
    
     
    <div className=" bg-white  w-full h-full sticky z-20 top-0">
    <div className="h-full text-center text-lg px-0 md:px-10 lg:px-20 py-4 text-gray font-normal font-roboto justify-center flex ">
      <div className="relative w-full md:w-3/4 z-10  h-full text-left text-3xl pt-3 text-gray font-roboto font-bold break-words">
        <span className='px-6'>
    Newsletter
      </span>
    </div>
  </div>
      <div className="h-full text-center text-base px-0 md:px-10 lg:px-20 py-4 text-gray font-normal font-roboto justify-center flex ">

        <div className='w-full md:w-3/4 mt-2 mb-24'>
          <div className="h-full px-6 pt-4 text-gray  w-full text-left hyphens-auto text-base md:text-base font-roboto font-normal">
       {language=="de"? <span> Nichts mehr verpassen! Das Internationales Kinder- und Jugendchorzentrum Christuskirche Hannover e.V. bietet einen Newsletter an. Wir informieren über kommende Veranstaltungen des Chorzentrums und aktuelle Informationen zum internationalen Kinder- und Jugendchor-Festival.</span>
       :<span>Don't miss anything! The International Children and Youth Choir Center Christuskirche Hannover e.V. offers a newsletter. We provide information about upcoming events at the choir center and current information about the International Children's and Youth Choir Festival.</span>} 
        </div>
        </div>
        

       
      </div>

      <iframe
        className="block w-full -mt-28 mb-12"
        
        height="550px"  // Set height to '100%' to fill the available height
        src="https://23c71b54.sibforms.com/serve/MUIFAGY_Kz2_z6PGQrKZX1OI0JEz0rh8HOF-LUqsPfyDEua3YWTjaT2sM4nDc4qNPM5F0OI9ncXh2pNhQUZ4mes9VOYQoQLrza5bENYfJk_MdFfJ51SfcLK1QtWmbIdaL4dOWka2LMuI6TXJSxodmKxeaFzU75OBBKVjw51HSmK9qeKx8BzXpK-otvXbZ_ILVesGMwcSBQPan4Eb"
        frameBorder="0"
        scrolling="no" // Set scrolling to 'no' to avoid the scrollbar
        allowFullScreen
      />
    </div>

   <div className='mb-12'></div>
 
</div>
      <Footer/>
        </React.Fragment>
    );
}

export default FooterDetailsNewsletter;