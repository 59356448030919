import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import logo_chorzentrum from '../grafiken/logo_chorzentrum.svg';
import logo_chorzentrumTransparent from '../grafiken/Kinderchornzentrum_transparent.png';
import { LikeContext } from '../hooks/likeContext';
import LanguageSwitch from '../elements/languageSwitchMenu';
import { LanguageContext } from '../hooks/languageContext';
import gsap from 'gsap';

function Header(props) {

  const [likes, setLikes] = useContext(LikeContext);
  const [language, setLanguage] = useContext(LanguageContext);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    const heart = document.querySelector('.mask-heart');


    gsap.killTweensOf(heart);
    const heartAnimation = gsap.timeline();
    heartAnimation.to(heart, { duration: 0.15, scale: 1.2, ease: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)' });
    heartAnimation.to(heart, { duration: 0.15, scale: 1, delay: 0.15, ease: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)' });

    return () => {
      heartAnimation.kill();
    };
  }, [likes]);

  return (
    <React.Fragment>
      <div className="navbar w-screen fixed top-0 pointer-events-none z-50">
        
        <div className="flex-auto mt-2 w-2/3 md:w-4/6 lg:w-5/6 ">
          <Link to="/" className='pointer-events-auto'>
            <img className="w-28 lg:w-48 ml-4" src={logo_chorzentrumTransparent} />
          </Link>
        </div>

<div className='flex flex-auto w-1/3 md:w-2/6 lg:w-1/6 justify-end'>
        <div className="flex-auto flex flex-row-reverse w-1/2  pointer-events-none">

          <Link to="../likedveranstaltungen/">  
          <div className=" justify-end ">
        
            <label tabIndex={0} className="z-10  btn btn-ghost btn-circle border-none bg-opacity-0 hover:bg-opacity-0 avatar  pointer-events-auto">
              <div className={'cursor-pointer mask mask-heart w-10 h-10 mt-2 bg-orange  z-50 '}>
              </div>
              <span className="badge badge-sm indicator-item bg-transparent ml-0 border-none opacity-100 z-50 font-robotoserif font-semibold text-lg">{likes ? likes.length : 0}</span>
            </label>

          </div>
          </Link>
        </div>

        <div className="flex-auto w-1/2">
          <div className="dropdown dropdown-end -inset-x-5 md:inset-x-0">
            <label tabIndex={0} className="z-10 btn avatar mt-2 pointer-events-auto border-none bg-opacity-0 hover:bg-opacity-0">
              <button onClick={toggleDropdown} className="btn m-0 text-center bg-opacity-0 hover:bg-opacity-0 border-none shadow-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 48" className="w-12 h-12 mx-auto stroke-current">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M0 12h32M0 24h32M0 36h32"></path>
                </svg>
              </button>
            </label>

            {isOpen && (
              <React.Fragment>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-20 p-2 shadow bg-base-100 rounded-box w-80 pointer-events-auto">
              <li>
                <Link to="../veranstaltungen/">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                    {language=="de"? <>Veranstaltungs-Kalender</>:<>Event Calender</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
              </li>
              <li>
                <Link to="../choere/littlesingersofarmenia/">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                  {language=="de"? <>Gastchöre</>:<>Guest Choirs</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
              </li>
              <li>
                <Link to="../choere/maedchenchorhannover/">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                  {language=="de"? <> Gastgeberchöre</>:<>Host Choirs</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
                <Link to="../choere/willtoddb/">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                  {language=="de"? <> Projekte</>:<>Projects</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
                <Link to="../team/">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                  {language=="de"? <>Team</>:<>Team</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
                <Link to="../footer/jobs">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                  {language=="de"? <>Helfende Hände</>:<>Helping Hands</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
                <Link to="../footer/downloads">
                  <a className="justify-between text-center text-sm text-gray font-roboto font-bold">
                  {language=="de"? <>Downloads</>:<>Downloads</>}
                    {/** <span className="badge">New</span>*/}
                  </a>
                </Link>
                </li>
                <li>

              <a className=" text-sm text-gray font-roboto font-bold mt-6 pointer-events-none">
                  {language=="de"? <> Kontakt</>:<>Contact</>}
                  </a>
               
                  </li>
                  <li className="text-xs text-gray font-roboto font-bold">
                                    <a href="tel: 491756495015">+49 175 64 95 015</a>
                  </li>
                  <li className=" text-xs text-gray font-roboto font-bold">
                  <a href="mailto:info@internationales-chorzentrum.de"> info@internationales-chorzentrum.de</a>
                    {/** <span className="badge">New</span>*/}
                  
                           
                </li>
                <li className='pointer-events-none'>
                <LanguageSwitch />
              </li>
            </ul>
  
</React.Fragment>
            )}
          </div>
        </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 z-10" onClick={() => setIsOpen(false)}></div>
      )}
    </React.Fragment>
  );

}


export default Header;