import React, { } from 'react';

function SubHeadline(props) {


    return (
                <div className="h-full w-full text-center text-xl  md:text-2xl lg:text-3xl pt-4 text-gray font-robotserif">
                  {props.children}
                </div>
    )
}

export default SubHeadline;