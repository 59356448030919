import React, {useState} from 'react';
import { PortableText } from '@portabletext/react';
import { Disclosure } from '@headlessui/react';


function Impression(props) {

    const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };


    return ( 
    
        <div role="listitem" class="xl:w-1/3 lg:mx-3 sm:w-3/4 md:w-2/5 relative mt-4 mb-24 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div class="rounded overflow-hidden ">
                <div class=" mt-4 w-full flex justify-center">
                    <div class="h-100 w-100">
                       <a href={props.link}> <img src={props.image} alt={props.name} role="img" class="rounded-full object-cover h-full w-full shadow-md" /></a>
                    </div>
                </div>
                <div class="px-6 mt-8">
                    <h1 class="font-normal font-roboto text-sm text-center mb-1">{props.name}</h1>
                </div>
            </div>
        </div>
                           
    );
}

export default Impression;