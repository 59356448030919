import React, {useContext} from 'react';
import Switcher6 from '../elements/switcher6';
import { LanguageContext } from '../hooks/languageContext';

function LanguageSwitchMenu(props) {

    const [language, setLanguage] = useContext(LanguageContext);

    function HandleLanguageChange(e) {
      setLanguage(e);         
  };

    return(
       

                <div className='w-60  mt-6 flex '>
                  <div className={`w-28 text-center ml-0 text-gray font-roboto font-bold   text-sm 
                  ${
                    language ==="en"? ' text-gray' : ' text-yellow'
                  }`}>
                    deutsch
                  </div>
                  <div className='flex-1 text-center pointer-events-auto'>
                    <Switcher6 language={language} onSelectLanguage={(e)=>HandleLanguageChange(e)}/>
                  </div>
                  <div className={`w-28 text-center mr-6 font-bold font-roboto  text-gray font-roboto text-sm 
                  ${
                    language ==="en"? ' text-yellow' : ' text-gray'
                  }`
                }>
                    english
                  </div>
                  
              </div>
          
    );

}


export default LanguageSwitchMenu;