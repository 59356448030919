import React, { createContext, useContext, useState, useEffect } from 'react';

const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [showBanner, setShowBanner] = useState(null);
  const [consent, setConsent] = useState(null);

  const reShowBanner = () => {
    setShowBanner(!showBanner);
  };

  useEffect(()=>{
    const hasConsent = localStorage.getItem('chorfestival2024Cookie');
    setConsent(hasConsent);
  },[]);

  const setCookieConsent = (value) => {
    
        setConsent(value);
        //window.location.reload();
    


  }
  

  return (
    <CookieContext.Provider value={{ showBanner, reShowBanner,consent, setCookieConsent}}>
      {children}
    </CookieContext.Provider>
  );
};

export const useCookieContext = () => {
  return useContext(CookieContext);
};