import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);

const dayNamesGerman = ["So", "Mo", "Di", "Mi", "Do", "Fr","Sa"];
const dayNamesEnglish = ["sunday", "monday", "tuesday", "wendsday", "thursday", "friday","saturday"];

const monthNamesGerman = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
"Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
];

const monthNamesEnglish = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];


export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }

export const GetVeranstaltungDatum=(veranstaltung)=>
    {
        return FormatDateTime(new Date(veranstaltung.termin)); 
    }

    export const GetVeranstaltungLink=(veranstaltung)=>
    {
        return veranstaltung.link.current;
    }

    export const GetVeranstaltungFormat=(veranstaltung, language)=>
    {
        let text="undefined";
        if(veranstaltung.format)
        {
            text= veranstaltung.format.name[0].value;
            if(language==="en" && veranstaltung.format.name[1])
            {
                text=veranstaltung.format.name[1].value;
            }
        }
        

        return text;
    }

    export const GetVeranstaltungFormatObject=(veranstaltung)=>
    {

            return veranstaltung.format;

    }

    export const GetVeranstaltungFormatLink=(veranstaltung)=>
    {
        if(veranstaltung.format)
            return veranstaltung.format.link.current;
        else return "undefined";
    }
    export const GetVeranstaltungDatumFormatA=(veranstaltung)=>
    {

        let datetime=new Date(veranstaltung.termin);
        let monat=monthNamesGerman[datetime.getMonth()];
        let wochentag=dayNamesGerman[datetime.getDay()];
        let datestring
        let tag=datetime.getDate();
        if(window.innerWidth>=700)
            datestring=wochentag+". "+TwoDigits(tag)+". "+monat;
        else
            datestring=TwoDigits(tag)+". "+monat;
        
        return datestring; 
    }

    export const GetVeranstaltungZeit=(veranstaltung)=>
    {
        let datetime=new Date(veranstaltung.termin);
        return TwoDigits(datetime.getHours()) + ":" + TwoDigits(datetime.getMinutes());
    }

    export const GetVeranstaltungName=(veranstaltung, language, showOrt)=>
    {
        let text="undefined";
        
        if(veranstaltung.format && veranstaltung.name)
        {

            if(veranstaltung.format.name[0].value==="Get together")
            {

                text=veranstaltung.name[0].value;
                if(language==="en" && veranstaltung.name[1])
                {
                    text=veranstaltung.name[1].value;
                }
            }
            else if(veranstaltung.format.name[0].value==="Eröffnung")
            {

                text=veranstaltung.name[0].value;
                if(language==="en" && veranstaltung.name[1])
                {
                    text=veranstaltung.name[1].value;
                }
            }
            else if(veranstaltung.format.name[0].value==="Finale")
            {

                text=veranstaltung.name[0].value;
                if(language==="en" && veranstaltung.name[1])
                {
                    text=veranstaltung.name[1].value;
                }
            }
            else
            {

                text=veranstaltung.format.name[0].value+": ";
                text+=veranstaltung.choere[0].name[0].value;
                if(veranstaltung.choere.length===2)
                {
                    text+=" & "+veranstaltung.choere[1].name[0].value;
                }
                if(showOrt)text+=", "+veranstaltung.veranstaltungsort[0].name[0].value;

                if(language==="en")
                {
                    text=veranstaltung.format.name[1].value+": ";
                    text+=veranstaltung.choere[0].name[1].value;
                    if(veranstaltung.choere.length===2)
                {
                    text+=" und "+veranstaltung.choere[1].name[1].value;
                }
                    if(showOrt)
                    {
                        if(veranstaltung.veranstaltungsort[0].name[1])
                            text+=", "+veranstaltung.veranstaltungsort[0].name[1].value;
                        else
                            text+=", "+veranstaltung.veranstaltungsort[0].name[0].value;
                    }
                }

            }
        }
        return text;
/*
        if(veranstaltung.name)
            return veranstaltung.name[0].value;
        else return "undefined";*/
    }

    export const GetVeranstaltungBeschreibung=(veranstaltung, language)=>
    {
        let text=
        {
            "style": "normal",
            "_type": "block",
            "children": [
              {
                "_type": "span",
                "text": "The event description is coming soon!"
              }
            ]
          };

          if(veranstaltung.beschreibung)
          {
              if(language === "de")
              text=veranstaltung.beschreibung.de;
            if(language==="en")
              text=veranstaltung.beschreibung.en;
          }
          return text;


    }

    export const GetVeranstaltungChoere=(veranstaltung)=>
    {
        if(veranstaltung.choere)
            return veranstaltung.choere;
        else return []
    }

    

    export const GetVeranstaltungTicketLink=(veranstaltung)=>
      {
        if(veranstaltung.ticketlink)return veranstaltung.ticketlink;
        else return null;
      }

    export const GetVeranstaltungOrt=(veranstaltung,language)=>
    {
        let text=null;
        if(language==="en")
        {
            if(veranstaltung.veranstaltungsort[0].name[1])
            {
                text=veranstaltung.veranstaltungsort[0].name[1].value;
            }
            else
            {
                text=veranstaltung.veranstaltungsort[0].name[0].value;
            }
        }
        else
        {
            if(veranstaltung.veranstaltungsort[0].name)
                text=veranstaltung.veranstaltungsort[0].name[0].value;
        }

        return text;
    }

    export const GetVeranstaltungOrtKoordinaten=(veranstaltung)=>
    {
        if(veranstaltung)
        return veranstaltung.veranstaltungsort[0].koordinaten;
        else return null; 
    }

    export const IsVeranstaltungCity=(veranstaltung)=>
    {
        if(veranstaltung.region==true) return "Region";
        else if(veranstaltung.outdoor==true) return "Outdoor";
        else return "Stadt"
        //console.log(veranstaltung);
        //return veranstaltung.stadt;
    }

    export const GetVeranstaltungOrtLink=(veranstaltung)=>
    {
        if(veranstaltung.veranstaltungsort[0].link)
        return veranstaltung.veranstaltungsort[0].link.current;
        else return null;
    }

    export const GetVeranstaltungOrtBild=(veranstaltung,width, height)=>
    {
        if(veranstaltung.veranstaltungsort)
        return getSanityImageUrl(veranstaltung.veranstaltungsort[0].bild.asset).width(width).height(height).focalPoint(veranstaltung.veranstaltungsort[0].bild.hotspot.x, veranstaltung.veranstaltungsort[0].bild.hotspot.y).fit("crop").crop("focalpoint").url()
        else return null;
    }

    export const TwoDigits=(digitString)=>
    {
        return digitString.toString().padStart(2, '0');
    }

    export const FormatDateTime =(datetime)=>
    {
        return TwoDigits(datetime.getDate())  + "." + TwoDigits(datetime.getMonth()+1) + "." + datetime.getFullYear() + " " +
        TwoDigits(datetime.getHours()) + ":" + TwoDigits(datetime.getMinutes());
    }