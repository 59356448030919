import React, { } from 'react';

function TableLine(props) {

    return (
                <div className={"text-sm md:text-base font-normal font-roboto "+props.className}>
                  {props.children}
                </div>
    )
}

export default TableLine;