import React, {useState} from 'react';
import { PortableText } from '@portabletext/react';
import { Disclosure } from '@headlessui/react';


function TeamMember(props) {

    const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };


    return ( 
    
        <div role="listitem" class="xl:w-1/3 lg:mx-3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
            <div class="rounded overflow-hidden shadow-md bg-white">
                <div class="absolute -mt-20 w-full flex justify-center">
                    <div class="h-32 w-32">
                        <img src={props.image} alt="Display Picture of Silene Tokyo" role="img" class="rounded-full object-cover h-full w-full shadow-md" />
                    </div>
                </div>
                <div class="px-6 mt-16">
                    <h1 class="font-bold text-3xl text-center mb-1">{props.name}</h1>
                    <p class="text-gray-800 text-sm text-center">{props.rolle}</p>
                    <p class="text-gray-800 text-sm text-center"><a href={"mailto:"+props.mail}>{props.mail}</a></p>
                    <p class="text-left text-gray-600 text-base pt-3 font-normal">
                        


                    <div className="max-w-prose mx-auto">
  <Disclosure>
    {({ open }) => (
      <div className="group cursor-pointer">
        <Disclosure.Panel>
          {open && (
            <div className="mb-3 overflow-visible">
              <PortableText value={props.beschreibung}/>
               
            </div>
          )}
        </Disclosure.Panel>
        <div className={`mb-3 overflow-ellipsis ${showFullText || open ? 'hidden' : 'overflow-hidden'} h-20`}>
          <PortableText value={props.beschreibung}/>
          
        </div>

        <Disclosure.Button> 
          {props.language=="de" ?
                    <span className="link-hover text-lg font-roboto">{open ? 'Weniger lesen' : 'Mehr lesen'}</span>
                    :
          <span className="link-hover text-lg font-roboto">{open ? 'Read less' : 'Read more'}</span>
        }
        </Disclosure.Button>
        
      </div>
    )}
  </Disclosure>
</div>




                    </p>
                    <div class="w-full flex justify-center pt-5 pb-5">
                        {/** 
                        <a href="javascript:void(0)" class="mx-5">
                            <div aria-label="Twitter" role="img">
                            <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a> 
                            </div>
                        </a>
                        */}
{/** 
                        <a href="javascript:void(0)" class="mx-5">
                            <div aria-label="Instagram" role="img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#718096" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram">
                                    <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                </svg>
                            </div>
                        </a>*/}
                    </div>
                </div>
            </div>
        </div>
                           
    );
}

export default TeamMember;