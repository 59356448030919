import React, { useEffect, useState, useContext } from 'react';
import { useContent } from '../hooks/content-context';
import LikeButton from '../elements/likeButton';
import { GetVeranstaltungOrtLink,GetVeranstaltungTicketLink, GetVeranstaltungDatum,GetVeranstaltungOrt,GetVeranstaltungFormat, GetVeranstaltungFormatLink, GetVeranstaltungName, GetVeranstaltungDatumFormatA, GetVeranstaltungZeit, GetVeranstaltungLink, GetVeranstaltungChoere,IsVeranstaltungCity } from "../helperFunctions/helperFunctionsVeranstaltung";
import VeranstaltungTermin from '../elements/VeranstaltungTermin';
import { useParams,Link,useNavigate  } from 'react-router-dom';

function UpcomingEvents(props) {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [commingUpEvents, setCommingUpEvents] = useState(null);


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  
  function ButtonLink({ to,className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

  const { state: { veranstaltungen } } = useContent();

  function isSameDate(veranstaltung,index){
    
    if(index===0) return false;
    if(GetVeranstaltungDatumFormatA(commingUpEvents[index - 1]) === GetVeranstaltungDatumFormatA(veranstaltung))
    return true;
    else return false;
  }

  useEffect(() => {
    if(veranstaltungen)
      {
        let currentDate = new Date();
        currentDate.setHours(currentDate.getHours() - 1);
        const veranstaltungenComingUp = veranstaltungen.filter(veranstaltung => {
          const eventDate = new Date(veranstaltung.termin);
          return eventDate > currentDate;
      });

      console.log(veranstaltungenComingUp);
      setCommingUpEvents(veranstaltungenComingUp);
      }

  },[veranstaltungen])

  /*
  veranstaltungenComingUp.sort((a, b) => {
    const dateA = new Date(a.termin);
    const dateB = new Date(b.termin);
    return dateA - dateB;
});
*/
  return (
    <React.Fragment>
      {
        commingUpEvents &&
      
      <div className={props.className + " card bg-white max-h-max  shadow-none md:shadow-xl z-20"}>

        <div className="card-body h-full">
          <h2 className="font-roboto text-2xl w-full md:text-3xl  text-gray">{props.language ==="en"? <span>Upcoming Events</span>:<span>Die nächsten Termine</span>}</h2>
          <div className='h-full overflow-y-auto pointer-events-auto'>

            <table className="table table-sm px-1 md:px-3">

              {commingUpEvents && commingUpEvents.slice(0, 6).map((veranstaltung, index) => (
                 <VeranstaltungTermin screenWidth={screenWidth} ticketlink={GetVeranstaltungTicketLink(veranstaltung)} veranstaltung={veranstaltung} name={GetVeranstaltungName(veranstaltung,props.language,  true)} choere={GetVeranstaltungChoere(veranstaltung)} ort={GetVeranstaltungOrt(veranstaltung)} format={GetVeranstaltungFormat(veranstaltung)} formatLink={GetVeranstaltungFormatLink(veranstaltung)} zeit={GetVeranstaltungZeit(veranstaltung)} id={veranstaltung._id} datum={GetVeranstaltungDatumFormatA(veranstaltung)} newDate={isSameDate(veranstaltung,index)} link={GetVeranstaltungLink(veranstaltung)} stadt={IsVeranstaltungCity(veranstaltung)}/>
              ))}

            </table>

          </div>
          <ButtonLink to="../veranstaltungen/" className="btn btn-neutral font-roboto mt-12">
          {props.language ==="en"? <span>Show more</span>:<span>Mehr anzeigen</span>}</ButtonLink>
        </div>
      </div>
      }
    </React.Fragment>
  );

}


export default UpcomingEvents;