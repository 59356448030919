
import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from 'react';


import { useContent } from './hooks/content-context';
import { LanguageContext } from './hooks/languageContext';


import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';

import UpcomingEvents from './components/upcomingEvents';
import { PortableText } from '@portabletext/react';
import Sponsors from './components/sponsors';
import Headline from './elements/headline';
import SubHeadline from './elements/subHeadline';
import Paragraph from './elements/paragraph';
import ChorCarousel from './elements/ChorCarousel';
import './index.css';
import welcome_header from './grafiken/WelcomeSingen.gif';
import skyline from './grafiken/bg_loop_02.gif'; //skyline.png
import welcomeBubble from './grafiken/welcomeblase.gif'; //welcomeblase.png
import addDatesLoop from './grafiken/adddates.gif';

import { isMobileSafari } from 'react-device-detect';
import MapSection from './elements/MapSection';
import background from './grafiken/bg_loop_02.gif';
import Footer from './components/footer';
import { GetIntroHeadline, GetIntroText, GetVeranstaltungenHeadline, GetVeranstaltungenText, GetLocalChoirText, GetLocalChoirHeadline, GetInternationalChoirHeadline, GetInternationaleChoirText, GetProjekteHeadline, GetProjekteText } from "./helperFunctions/helperFunctionsTextbausteine"

import LanguageSwitch from './elements/languageSwitch';
import Header from './components/header';
import { useCookieContext } from './hooks/cookie-context';
import ButtonBar from './elements/buttonBar';
import ScrollButton from './elements/scrollButton';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

function App() {
  const { consent, reShowBanner } = useCookieContext();

  function refreshTrigger() {
    ScrollTrigger.refresh(true);
  }

  const [mapConsent, setMapActive] = useState(false);
  const [contentSet, setContentSet] = useState(null);
  const [triggerSet, setTriggerSet] = useState(null);
  const [language, setLanguage] = useContext(LanguageContext);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [shouldSetupTrigger, setTriggerSetup] = useState(null);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
    refreshTrigger();
  };

  useEffect(() => {

    setMapActive(consent);
    //setTimeout(()=>{refreshTrigger()}, 150);
  }, [consent]);

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);


  const IntroText = useRef(null);
  const IntroBubble = useRef(null);
  const WolkeRef = useRef(null);
  const ChorRef = useRef(null);
  const SkylineRef=useRef(null);
  const MapPopup = useRef(null);
  const MapScreenRef = useRef(null);
  const cityBackgroundRef = useRef(null);
  const mapRef = useRef(null);
  const mapRefContainer = useRef(null);
  const MoodTextA = useRef(null);
  const ParallaxCityContainerRef = useRef(null);
  const GuestRef = useRef(null);
  const scrollContainerRef= useRef(null);
  const UpcomingEventsContainerRef=useRef(null);
const UpcomingEventsRef=useRef(null);
const footerRef = useRef(null);

  const { state: { choere } } = useContent();
  const { state: { choereRegional } } = useContent();
  const { state: { specialGuests } } = useContent();
  const { state: { textbausteine } } = useContent();
  const [scroll, setScroll] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
//  const [mapRegion, setMapRegion] = useState(JSON.parse(regionMapLocation));
//  const [isMapRegion, setIsMapRegion] = useState(1);
//  const [mapOrt, setMaport] = useState(0);

  const handleParallax = () => {
    
    
    const hideFirstElementTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: IntroBubble.current,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        markers: false,
      },
    });

    hideFirstElementTimeline.to(ChorRef.current, { translateY: '+15%' }, 0);
    hideFirstElementTimeline.to(WolkeRef.current,  { translateY: '+40%' }, 0);
    hideFirstElementTimeline.to(SkylineRef.current,  { translateY: '+30%' }, 0);
  };

  useEffect(() => {
    if(contentSet)
    handleParallax();
  }, [contentSet]);

  useEffect(() => {
    if (choere && choereRegional && specialGuests && textbausteine) {
      console.log("CONTENTSET");
      setContentSet(true);
    }
  }, [textbausteine, choere, choereRegional, specialGuests])

  useEffect(() => {
    setViewHeight();

    window.addEventListener('resize', () => {
      setViewHeight()
    })
  }, [])

  function setViewHeight() {
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);

  }

  useEffect(() => {
    //setTimeout(()=>{refreshTrigger()}, 100);
    return function cleanup() {
      while (ScrollTrigger.getAll().length > 0) {
        ScrollTrigger.getAll()[0].kill();
      }
    };
  }, [])

  useEffect(() => {
    refreshTrigger();
  }, [language])

  //window.scrollTo(0, 0);

  useEffect(() => {

    if (contentSet && ParallaxCityContainerRef && GuestRef && IntroBubble && mapRefContainer && MapScreenRef && MapPopup && windowWidth > 640) {

      if (!isMobileSafari) {
        console.log("CONTENT SET?" + contentSet);
        setTriggerSet(true);
        const delay = setTimeout(setTriggerSetup(true), 200);
        return () => clearTimeout(delay);
      }
    }
  }, [contentSet, ParallaxCityContainerRef, windowWidth, GuestRef, IntroBubble, mapRefContainer, mapConsent, MapScreenRef, MapPopup]);

  useEffect(() => {
    var preUrl = document.referrer;
    if (preUrl === null || preUrl === "") {
      //setLanguage(0);
    }
    else {
      //setLanguage(0);
    }
  }, []);


  function HandleLanguageChange(e) {
    setLanguage(e);
    //setTimeout(()=>{refreshTrigger()}, 200);          
  };


  return (
    <React.Fragment>

      <div className='w-screen'>
        <Header screenWidth={screenWidth} />
      </div>
      {footerRef &&
<ScrollButton footerRef={footerRef}/>
}
      {contentSet &&

        <div className='absolute bg-white inset-0 w-full h-full'>
          {/** Welcome Bubble Section*/}
          <div className='relative'>
          <div ref={IntroBubble} className={`relative bg-yellow  h-screen/2 ${screenWidth == 1024 ? 'h-screen/2' : 'md:h-screen/2 lg:h-screen'} `}>
            <div ref={SkylineRef} className="absolute  inset-0  flex items-center  overflow-hidden justify-center">
              <img className="w-full h-full md:h-auto overflow-hidden scale-125 md:scale-100 lg-scale-100 object-cover md:-translate-y-20 lg:-translate-y-44" alt="welcome skyline" src={skyline} />
            </div>
            <div className="absolute inset-0 flex items-end justify-center">
            {/**  <img ref={ChorRef} className="w-full  md:w-1/2  object-cover" alt="welcome header" src={welcome_header} />*/}
            </div>

            <div className="absolute inset-0 flex -translate-y-14 md:-translate-y-20 lg:-translate-y-44 items-center justify-center">
              <img ref={WolkeRef} className="w-full md:w-1/2  object-cover" alt="welcome bubble" src={welcomeBubble} />
            </div>
          </div>

          {/** Intro Text Section*/}
          <div className='relative'>
          <div ref={IntroText} className='relative bg-white'>
            <div className=" bg-white items-center flex flex-col">
              <span className='pt-8 md-pt-16'>
                <LanguageSwitch />
              </span>
              <div className='w-full md:w-6/6 lg:w-4/6'>
                <Headline className="pt-14 md:pt-28">
                  {textbausteine && GetIntroHeadline(textbausteine[0], language)}
                </Headline>
                {language === "en" ? <SubHeadline>08. - 12. MAY 2024</SubHeadline> : <SubHeadline>08. - 12. MAI 2024</SubHeadline>}
                <Paragraph className="text-center">
                  {textbausteine && <PortableText value={GetIntroText(textbausteine[0], language)} />}
                </Paragraph>
              </div>
              <img src={addDatesLoop} className="w-2/3 md:w-60 lg:w-96 rounded-lg mt-12 mb-12" />
              <ButtonBar language={language} />
            </div>
          </div>
          </div>
          </div>

          {/** Internationale Choere Section*/}
          <section>
            <div className='relative z-20 bg-yellow'>
              <ChorCarousel type={0} choere={choere} screenWidth={screenWidth} background="bg-neutral-50" headline={textbausteine && GetInternationalChoirHeadline(textbausteine[0], language)} text={textbausteine && GetInternationaleChoirText(textbausteine[0], language)} />
            </div>

          {/** Regionale Choere Section*/}
            <div className='relative z-20 bg-yellow'>
              <ChorCarousel chorindex={2} type={0} choere={choereRegional} screenWidth={screenWidth} background="bg-white" headline={textbausteine && GetLocalChoirHeadline(textbausteine[0], language)} text={textbausteine && GetLocalChoirText(textbausteine[0], language)} />
            </div>
          </section>

          {/** SpecialGuests Section*/}
            <div ref={GuestRef} className='relative inset-0 z-30 bg-transparent w-full'>
              <ChorCarousel type={0} choere={specialGuests} screenWidth={screenWidth} background="bg-neutral-50" headline={textbausteine && GetProjekteHeadline(textbausteine[0], language)} text={textbausteine && GetProjekteText(textbausteine[0], language)} />
            </div>


                        {/* City Background Section
                        
                        <div ref={cityBackgroundRef} className="absolute inset-0 top-1/2  h-screen z-10 bg-yellow overflow-hidden">
              <img className="mx-auto h-screen object-cover object-bottom transform scale-150" alt="HannoverBackground" src={background}></img>
              <div ref={MoodTextA} className="px-4 inset-0 absolute w-full z-20 text-center break-words">
                {textbausteine && language === "en" ? <Headline>Voices ring out in our city and region</Headline> : <Headline>Stimmen erklingen in unserer Stadt und Umland</Headline>}
              </div>
            </div>
*/}
  
            {/* Map Section */}
            <div className='relative h-auto '> {/**top-1/2 */} {/** invisible md:visible */}
         
              <div ref={mapRefContainer} className="relative inset-0 z-30"> 
                <div ref={mapRef} className="relative w-full">
                  <MapSection headline={textbausteine && GetVeranstaltungenHeadline(textbausteine[0], language)}
                    text={GetVeranstaltungenText(textbausteine[0], language)}
                    mapConsent={mapConsent}
                    reShowBanner={(value) => reShowBanner(value)}
                    language={language}
                  />
                </div>
              </div>
              </div>
            {/* Upcoming Section */}
          <div  className='relative z-10 h-auto '> {/*//top-1/2*/}
            <div  className="relative bg-white bg-opacity-100  w-full justify-center items-center flex z-30 py-8 mt-0 md:mt-24" >
              <UpcomingEvents language={language} className="w-full md:w-2/3 xl:w-2/3 2xl:w-2/3"/>
            </div>
          </div>

            {/* Footer Section */}
          <div  className='relative  z-10 mt-6 md:mt-6 bg-neutral-50'>{/*//top-1/2*/}
            <Sponsors language={language} />
            <Footer ref={footerRef}/>
          </div>
        </div>
      }
    </React.Fragment>
  );
}
export default App;


