import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);

export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }

    export const GetOrtName=(ort,language)=>
    {
       // return ort.name[0].value;

        let text=null;
        if(language==="en")
        {
            if(ort.name[1])
            {
                text=ort.name[1].value;
            }
            else
            {
                text=ort.name[0].value;
            }
        }
        else
        {
            if(ort.name)
                text=ort.name[0].value;
        }

        return text;
    }

    export const GetOrtLink=(ort)=>
    {
        return ort.link.current;
    }

    export const GetOrtBeschreibung=(ort,language)=>
    {
        let text=
        {
            "style": "normal",
            "_type": "block",
            "children": [
              {
                "_type": "span",
                "text": "More infos coming soon"
              }
            ]
          };

        if(ort.beschreibung)
        {
            if(language === "de")
            text=ort.beschreibung.de;
          if(language==="en")
            text=ort.beschreibung.en;
        }
        return text;
    }

    export const GetOrtKoordinaten=(ort)=>
    {
        if(ort)
        return ort.koordinaten;
        else return null; 
    }

    export const GetOrtBild=(ort,width, height)=>
    {
        if(ort.bild)
            return getSanityImageUrl(ort.bild.asset).width(width).height(height).focalPoint(ort.bild.hotspot.x, ort.bild.hotspot.y).fit("crop").crop("focalpoint").url()
        else return null;
        }

    export const TwoDigits=(digitString)=>
    {
        return digitString.toString().padStart(2, '0');
    }

    export const FormatDateTime =(datetime)=>
    {
        return TwoDigits(datetime.getDate())  + "." + TwoDigits(datetime.getMonth()+1) + "." + datetime.getFullYear() + " " +
        TwoDigits(datetime.getHours()) + ":" + TwoDigits(datetime.getMinutes());
    }