import React, { useEffect, useState, useContext, useRef } from 'react';

import { useParams, Link, useNavigate } from 'react-router-dom';
import { useContent } from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import { GetVeranstaltungDatum,GetVeranstaltungZeit, GetVeranstaltungFormatObject, GetVeranstaltungOrtLink, GetVeranstaltungName, GetVeranstaltungBeschreibung, GetVeranstaltungChoere, GetVeranstaltungOrtKoordinaten, GetVeranstaltungOrt, GetVeranstaltungOrtBild, GetVeranstaltungDatumFormatA, GetVeranstaltungFormat, GetVeranstaltungTicketLink } from "../helperFunctions/helperFunctionsVeranstaltung";
import { GetChorDescription, GetChorName, GetChorImage, GetChorLink, GetChorLand } from "../helperFunctions/helperFunctionsChor";
import { GetFormatBeschreibung, GetFormatName } from "../helperFunctions/helperFunctionsFormate";
import { textFormat } from '../helperFunctions/helperFunctionsTextFormat.js';

import Navbar from './navbar';
import ChorCarousel from './chorCarousel';
import Carousel from './carousel';
import NavigationElement from '../elements/navigationElement';
import Map from './map';
import LikeButton from '../elements/likeButton';
import Header from '../components/header';
import Footer from '../components/footer';
import Headline from '../elements/headline.js';
import { LanguageContext } from '../hooks/languageContext';
import Paragraph from '../elements/paragraph.js';
import { gsap } from 'gsap/gsap-core';

const VeranstaltungDetails = ({ props }) => {

  const { id } = useParams();
  const { state: { veranstaltungen } } = useContent();
  const [veranstaltung, setVeranstalung] = useState(0);
  const [veranstaltungIndex, setVeranstaltungIndex] = useState(0);
  const [mapOrt, setMaport] = useState(0);
  const [mapRegion, setMapRegion] = useState("region");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useContext(LanguageContext);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  function ButtonLink({ to, className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

  useEffect(() => {
    if (veranstaltungen && id) {
      let acvtiveVeranstaltung = GetVeranstaltungByLink(id);
      setVeranstalung(acvtiveVeranstaltung);
    }
  }, [veranstaltungen, id])

  function GetVeranstaltungByLink(linkid) {
    var veranstaltungfound = null;
    for (let i = 0; i < veranstaltungen.length; i++) {
      if (veranstaltungen[i].link.current === linkid) {
        veranstaltungfound = veranstaltungen[i];
        setVeranstaltungIndex(i);
        break;
      }
    }
    return veranstaltungfound;
  }

  function GetNextVeranstaltung() {
    let index = 0;
    if (veranstaltungIndex < veranstaltungen.length - 1) index = veranstaltungIndex + 1;
    return veranstaltungen[index].link.current;
  }

  function GetPrevVeranstaltung() {
    let index = veranstaltungen.length - 1;
    if (veranstaltungIndex > 0) index = veranstaltungIndex - 1;
    return veranstaltungen[index].link.current;
  }

  function ButtonLink({ to, className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

  function fadeIn(image) {
    return gsap.timeline()
      .set(image, { zIndex: 1 })
      .fromTo(image, { opacity: 0 }, { opacity: 1, duration: 3 })
      .set(image, { zIndex: 0 }, "+=1.5")
  }

  const imageSlider = useRef(null);


  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 });
    if (imageSlider && GetVeranstaltungChoere(veranstaltung).length > 1) {
      const images = gsap.utils.toArray('.imgSlider img'); //imageSlider.current + img?
      const imagesAfterFirst = images.slice(1);
      imagesAfterFirst.forEach(image => tl.add(fadeIn(image)));
      tl.add(fadeIn(images[0]));
      tl.set(images[0], { opacity: 1 }, 0);
    }
    return function cleanup() {
      tl.kill();
    };
  }, [imageSlider, veranstaltung])




  return (
    <div className='w-full h-full'>

      <Header />
      {veranstaltung &&
      <div className="relative w-full h-full justify-center">

          <div ref={imageSlider} className='imgSlider relative h-96 md:h-tall'>
            {
              GetVeranstaltungChoere(veranstaltung).map((chor, index) =>
              (
                <img
                  key={chor._id}
                  className="absolute top-0 left-0 h-96 md:h-tall w-full object-cover"
                  alt={GetChorName(chor, language)}
                  src={GetChorImage(chor, 1800, 600)}
                  style={{ zIndex: index }}
                />
              )
              )}
          </div>
        <div className='w-full bg-yellow'>
          <NavigationElement
            next={"../veranstaltungen/" + GetNextVeranstaltung()}
            prev={"../veranstaltungen/" + GetPrevVeranstaltung()}
            name={GetVeranstaltungName(veranstaltung, language)}
          />
        </div>

          <div className=" bg-white w-full h-full items-center flex flex-col">
<div className='bg-yellow w-full h-full items-center flex flex-col'>
            <div className="flex h-full justify-center	 text-center text-lg px-6 pt-4 text-gray font-light font-roboto w-full lg:w-4/6">
              <LikeButton big id={veranstaltung._id} />
            </div>

            <div className="h-full text-center text-2xl  sm:text-4xl pt-4 text-gray font-light">
              {GetVeranstaltungDatumFormatA(veranstaltung)} {GetVeranstaltungZeit(veranstaltung)} Uhr, {GetVeranstaltungOrt(veranstaltung, language)}
            </div>

            <div className="h-full text-center text-lg px-6 pt-4 text-gray font-light font-roboto w-full lg:w-4/6">
              <PortableText value={GetVeranstaltungBeschreibung(veranstaltung, language)} components={textFormat} />
            </div>

          
             

{GetVeranstaltungTicketLink(veranstaltung) !== null && (
    <ButtonLink className="btn mt-12 pointer-events-auto btn-neutral font-roboto" to={GetVeranstaltungTicketLink(veranstaltung)}>
  <span>{language === "de" ? "Tickets bestellen" : "Order tickets"}</span>
  </ButtonLink>
)}

{GetVeranstaltungTicketLink(veranstaltung) === null && (
   <button className="btn mt-12 pointer-events-auto btn-neutral font-roboto" to={"/footer/tickets"}>
  <span>{language === "de" ? "Kostenlose Veranstaltung" : "Free event"}</span>
  </button>
)}
            

            <div className='h-12'></div>
            </div>
<div className='bg-white w-full'>
            <div className="container  px-5 py-24 mx-auto">

              <Headline className="mb-16">
                <span>{language == "de" ? <span>Chöre</span> : <span>Choirs</span>}</span>
              </Headline>

              {GetVeranstaltungChoere(veranstaltung) && <Carousel choere={GetVeranstaltungChoere(veranstaltung)} screenWidth={screenWidth} />}

            </div>
</div>

<div className='w-full h-full items-center flex flex-col bg-base-200 pt-12'>
            <Headline className="mb-16">
              <span>{language == "de" ? <span>Zum Veranstaltungsort</span> : <span>To the venue</span>}</span>
            </Headline>

            <Link  to={"/veranstaltungsorte/" + GetVeranstaltungOrtLink(veranstaltung)}>
            <div>
              <img src={GetVeranstaltungOrtBild(veranstaltung, 320, 320)} alt={`Image Veranstaltungsort`} className="w-80 h-80 mb-8 object-cover object-center mask mask-circle bg-gray-100" />
            </div>
            </Link>

            <Paragraph className='w-full text-center justify-center mb-8'>
              <Link className='w-full justify-center text-center' to={"/veranstaltungsorte/" + GetVeranstaltungOrtLink(veranstaltung)}>

                {language == "de" ?
                  <span className='Link link-hover w-full text-center font-roboto'>Informiere Dich über andere Veranstaltungen an diesem Ort.</span>
                  :
                  <span className='Link link-hover w-full text-center font-roboto'>Find out about other events at this location.</span>}

              </Link>
            </Paragraph>

            <div className="h-full text-center text-lg px-6 pt-4 pb-12 text-gray font-roboto w-full lg:w-4/6">

              <span>{language == "de" ? <span>Mit Google Maps zum Ort navigieren? </span> : <span>Navigate to the location with Google Maps?</span>}</span>
              <a className='font-bold' href={'https://maps.google.com/?q=' + GetVeranstaltungOrtKoordinaten(veranstaltung).lat + ',' + GetVeranstaltungOrtKoordinaten(veranstaltung).lng}> Google navigation link</a>
            </div>
</div>
            <div className="w-full h-96  z-0 top-0 md:relative  overflow-hidden">
              {GetVeranstaltungOrtKoordinaten(veranstaltung) &&
                <Map region={{
                  "center": [GetVeranstaltungOrtKoordinaten(veranstaltung).lng, GetVeranstaltungOrtKoordinaten(veranstaltung).lat],
                  "zoom": 12
                }}
                  setOrt={setMaport}
                  activeMarker={GetVeranstaltungOrtKoordinaten(veranstaltung)}
                  oneLocationOnly={true} />
              }
            </div>

            <Headline className="mb-16 mt-16">
              Format: {GetVeranstaltungFormat(veranstaltung, language)}
            </Headline>
            <div className="h-full text-center text-lg px-6 pt-4 pb-24 text-gray font-light font-roboto w-full lg:w-4/6">
              {<PortableText value={GetFormatBeschreibung(GetVeranstaltungFormatObject(veranstaltung), language)} components={textFormat} />}
            </div>

          </div>
        

      </div>
}
      <Footer />
    </div>
  );
}

export default VeranstaltungDetails;