import React, { } from 'react';

function Paragraph(props) {


    return (
                <div className={"h-full font-roboto text-base md:text-lg px-6 pt-4 text-gray  w-full "+props.className}>
                  {props.children}
                </div>
    )
}

export default Paragraph;