import React, { useEffect, useState, useContext } from 'react';
import { useContent } from '../hooks/content-context';
import LikeButton from '../elements/likeButton';
import { GetVeranstaltungFormat,GetVeranstaltungFormatLink, GetVeranstaltungName, GetVeranstaltungOrt, GetVeranstaltungDatumFormatA, GetVeranstaltungZeit, GetVeranstaltungLink, GetVeranstaltungChoere,GetVeranstaltungTicketLink,IsVeranstaltungCity } from "../helperFunctions/helperFunctionsVeranstaltung";
import { LikeContext } from '../hooks/likeContext';
import VeranstaltungTermin from '../elements/VeranstaltungTermin';
import Headline from '../elements/headline';
const LikedVeranstaltungen = (props) => {

    const { state: { veranstaltungen } } = useContent();
    const [likes, setLikes] = useContext(LikeContext);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [likedVeranstaltungen, setLikedVeranstaltungen] = useState(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      }
    });

    useEffect(()=>{
      if(veranstaltungen)
      {
        let liked=[];
        veranstaltungen.forEach((veranstaltung,index) => {
          if(isVeranstaltungLiked(veranstaltung._id))
          {
            liked.push(veranstaltung);
          }
        });
        setLikedVeranstaltungen(liked);
      }
      
    },[veranstaltungen])

    function isVeranstaltungLiked(id)
  {
    if(likes)
    {
      
    if(likes.includes(id))
      return true;
    else 
      return false;
    }
    else return false;
  }

  function isSameDate(veranstaltung,index){
    if(index===0) return false;
    if(GetVeranstaltungDatumFormatA(likedVeranstaltungen[index - 1]) === GetVeranstaltungDatumFormatA(veranstaltung))
    return true;
    else return false;
  }

    return (
        <React.Fragment>
{likedVeranstaltungen &&
<div className={props.width + " " + props.height + " card bg-white max-h-max  shadow-xl z-20"}>

<div className="card-body px-1 md:px-5 h-full">
  <h2 className="card-title font-roboto font-bold text-gray"></h2>
  <div className="flex flex-col items-center justify-center mt-20">
      <div className="flex flex-col items-center justify-center">
        <div className="flex justify-center w-full">
          <div className="flex items-center mr-2 md:mr-8">
            <div className="bg-gray w-6 h-6 mr-2 ml-2 md:mr-4 md:ml-4"></div>
            <p className="text-base font-roboto">{windowWidth>=768?"Hannover Stadt":"Stadt"}</p>
          </div>
          <div className="flex items-center mr-2 md:mr-8">
            <div className="bg-blue w-6 h-6 mr-2 ml-2 md:mr-4 md:ml-4"></div>
            <p className="text-base font-roboto">{windowWidth>=768?"Hannover Stadt, Outdoor":"Outdoor"}</p>
          </div>
          <div className="flex items-center mr-2 md:mr-8">
            <div className="bg-green w-6 h-6 mr-2 ml-2 md:mr-4 md:ml-4"></div>
            <p className="text-base font-roboto">{windowWidth>=768?"Hannover Region und Niedersachsen":"Region"}</p>
          </div>
        </div>
      </div>
    </div>
  <div className='h-full overflow-y-auto pointer-events-auto'>

    <table className="table table-sm ">

            {likedVeranstaltungen.map((veranstaltung,index)=>(
                isVeranstaltungLiked(veranstaltung._id) &&
                <VeranstaltungTermin screenWidth={screenWidth} veranstaltung={veranstaltung} name={GetVeranstaltungName(veranstaltung,props.language, true)} ort={GetVeranstaltungOrt(veranstaltung,props.language)} choere={GetVeranstaltungChoere(veranstaltung)} format={GetVeranstaltungFormat(veranstaltung)} formatLink={GetVeranstaltungFormatLink(veranstaltung)} zeit={GetVeranstaltungZeit(veranstaltung)} id={veranstaltung._id} datum={GetVeranstaltungDatumFormatA(veranstaltung)} newDate={isSameDate(veranstaltung,index)} link={GetVeranstaltungLink(veranstaltung)} ticketlink={GetVeranstaltungTicketLink(veranstaltung)} stadt={IsVeranstaltungCity(veranstaltung)}/>
                
                ))}
                </table>
                </div>
                </div>
                </div>
            }
        </React.Fragment>
    )
}

export default LikedVeranstaltungen;