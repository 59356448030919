import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);

export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }

    

  
      export const GetImpressionImage=(impression, width, height)=>
      {
        if(impression.bild.hotspot)
          {
        return getSanityImageUrl(impression.bild.asset)
        .width(width).height(height).focalPoint(impression.bild.hotspot.x, impression.bild.hotspot.y)
        .fit("crop").crop("focalpoint").url();
      }
      else
      {
        return getSanityImageUrl(impression.bild.asset)
        .width(width).height(height);
      }
      }

      export const GetImageLink=(impression)=>
      {
        return getSanityImageUrl(impression.bild.asset);
      }

      export const GetImpressionName=(impression,language)=>
        {
          let text="undefined";
          if(impression.name)
          {
            text=impression.name;
          }
          return text;
        }

     