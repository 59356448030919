import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);



export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }


    export const GetFormatLink=(footer)=>
    {

            return footer.link.current;

    }
   
    export const GetFiles=(footer)=>
    {
      console.log("GETFILES");
      console.log(footer);
          if(footer.downloads)
            return footer.downloads;
          else 
            return null;
    }

    export const GetFooterName=(footer,language)=>
    {
        let text="undefined";
        if(footer.name)
        {
            text=footer.name[0].value;
            if(language==="en" && footer.name[1])
                text=footer.name[1].value;
        }
        console.log("FOOTER Name:"+text);
        return text;
        
    }

    export const GetFooterBeschreibung=(footer,language)=>
    {
        let text="undefined";
        if(footer.text)
        {
            text=footer.text.de;
            if(language==="en" && footer.text.en)
                text=footer.text.en;
        }
        console.log("FOOTER TEXT:"+text);
        return text;
    }

    export const GetFooterText=(footer,language)=>{
        
        let text=
        {
            "style": "normal",
            "_type": "block",
            "children": [
              {
                "_type": "span",
                "text": "More infos coming soon!"
              }
            ]
          };

        if(footer.text)
        {
          if(language === "de")
            text=footer.text.de;
          if(language==="en")
            text=footer.text.en;

        }
        console.log(text);
        return text;
      }
  
