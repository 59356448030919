import React, {useEffect, useContext} from 'react';

import { Link } from "react-router-dom";
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import {GetMemberName,GetMemberDescription,GetMemberImage,GetMemberPosition, GetMemberMail} from "../helperFunctions/helperFunctionsTeam";
import TerminElement from '../elements/terminElement';
import Header from './header';
import Footer from './footer';
import TeamMember from './teamMember';
import { LanguageContext } from '../hooks/languageContext';
import Skyline from '../grafiken/skyline.png';
function Team(props) {

    const {state: {team}} = useContent();
    const sanityImageBuilder = imageUrlBuilder(sanityClient);
    const [language, setLanguage] = useContext(LanguageContext);

    function getSanityImageUrl(source) {
        return sanityImageBuilder.image(source)
      }

    useEffect(()=>{
        if(team)
        {
            console.log("TEAM geladen");
            console.log(team);
        }
      },[team])

    return ( 
    <React.Fragment>
   <Header/>
   <div className="relative w-full h-52 flex items-center justify-center bg-yellow">
  {/* Image */}
  <img
    src={Skyline} // Replace with your image URL
    alt="Background Image"
    className="absolute inset-0 w-full h-full object-cover z-0"
  />

</div>
<div className="h-full text-center text-lg px-0 md:px-10 lg:px-20 py-4 text-gray font-normal font-roboto justify-center flex ">
      <div className="relative w-full md:w-3/4 z-10  h-full text-left text-3xl pt-3 text-gray font-roboto font-bold break-words">
        <span className='px-6'>
    Team
      </span>
    </div>
  </div>
                <div class="w-full mt-20 bg-gray-100 px-10 pt-10">
               
                    <div class="container mx-auto">
                        <div role="list" aria-label="Behind the scenes People " class="lg:flex md:flex sm:flex items-start xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                            

                           {team && team.map((member,index) => (
                                                           
                                <TeamMember language={language} image={GetMemberImage(member,450,450)} name={GetMemberName(member,language)} rolle={GetMemberPosition(member,language)} beschreibung={GetMemberDescription(member,language)} mail={member.mail}/>
                          
                           ))
                           } 
                         
                          
                           
                        </div>
                    </div>
                </div>
           
<Footer/>
    </React.Fragment> 
    );
}

export default Team;