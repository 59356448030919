import React, {useContext} from 'react';
import { LikeContext } from '../hooks/likeContext';

function LikeButton(props)  {

    const [likes, setLikes] = useContext(LikeContext);
    
    
  function isVeranstaltungLiked(id)
  {
    if(likes)
    {
    if(likes.includes(id))
      return true;
    else 
      return false;
    }
    else return false;
  }

  function likeVeranstaltung(id)
  {
    if(likes)
    {
    if(likes.includes(id))
      setLikes(likes.filter(_id => _id !== id ));
    else
      setLikes([...likes,id]);
    }
    else setLikes([id]);
  }


return(

 <div onClick={()=>likeVeranstaltung(props.id)} className={'cursor-pointer mask mask-heart hover:bg-orange hover:opacity-100 '+ (props.big ? ' w-12 h-12 ':' w-4 h-4 ') + (isVeranstaltungLiked(props.id) ? 'bg-orange opacity-100':'bg-gray opacity-10') +'  z-0'}>
 </div>

)
}

export default LikeButton;