import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);



export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }


    export const GetFormatLink=(format)=>
    {

            return format.link.current;

    }

    export const GetFormatByName=(formatname)=>
    {
        //check if one format has this name and return format object...
        return formatname;
    }
   
    export const GetFormatName=(format,language)=>
    {
        let text="undefined";
        if(format.name)
        {
            text=format.name[0].value;
            if(language==="en" && format.name[1])
                text=format.name[1].value;
        }
        return text;
        
    }

    export const GetFormatBeschreibung=(format,language)=>
    {
        let text="undefined";
        if(format.beschreibung)
        {
            text=format.beschreibung.de;
            if(language==="en" && format.beschreibung.en)
                text=format.beschreibung.en;
        }
        return text;
    }
