import React, { useEffect, useState, useContext } from 'react';
import { LikeContext } from '../hooks/likeContext';
import { Link } from "react-router-dom";
import { useContent } from '../hooks/content-context';
import { GetOrtBild, GetOrtName, GetOrtLink } from "../helperFunctions/helperFunctionsVeranstaltungsOrt";
import { GetVeranstaltungOrtLink, GetVeranstaltungFormat,GetVeranstaltungLink, GetVeranstaltungName, GetVeranstaltungDatumFormatA, GetVeranstaltungOrt, GetVeranstaltungZeit, GetVeranstaltungChoere } from "../helperFunctions/helperFunctionsVeranstaltung";
import LikeButton from '../elements/likeButton';
import VeranstaltungTermin from '../elements/VeranstaltungTermin';
import VeranstaltungTerminSmall from '../elements/VeranstaltungTerminSmall';
function LocationPopup(props) {

  const { state: { veranstaltungen } } = useContent();
  const [locationVeranstaltungen, setLocationVeranstaltungen] = useState([]);

  function ButtonLink({ to,className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }


  useEffect(() => {
    if (props.ort && veranstaltungen) {
      var thisLocationVeranstaltungen = [];
      veranstaltungen.forEach(veranstaltung => {
        if (GetVeranstaltungOrtLink(veranstaltung) === GetOrtLink(props.ort)) {
          let thisLocationVeranstaltung = {
            "name": GetVeranstaltungName(veranstaltung,props.language,false),
            "datum": GetVeranstaltungDatumFormatA(veranstaltung),
            "zeit": GetVeranstaltungZeit(veranstaltung),
            "link":GetVeranstaltungLink(veranstaltung),
            "ort":GetVeranstaltungOrt(veranstaltung,props.language),
            "format":GetVeranstaltungFormat(veranstaltung),
            "choere":GetVeranstaltungChoere(veranstaltung),
            "_id":veranstaltung._id
          }
          thisLocationVeranstaltungen.push(thisLocationVeranstaltung);
        }
      });
      setLocationVeranstaltungen(thisLocationVeranstaltungen);
    }
  }, [props, veranstaltungen])


  

  function isSameDate(veranstaltung,index){
    if(index===0) return false;
    if(locationVeranstaltungen[index - 1].datum === veranstaltung.datum)
    return true;
    else return false;
  }

  return (

    <React.Fragment>
      <div className={props.className+ "card bg-white max-h-max shadow-xl z-20 "}>

        <div className=" w-full h-auto"><img src={props.ort && GetOrtBild(props.ort, 384, 192)} />
        </div>

        <div className="card-body w-full  h-full bg-white bg-opacity-75 overflow-hidden pr-2">
        
          <h2 className="card-title h-full w-60  font-roboto font-bold text-gray line-clamp-1">{props.ort && GetOrtName(props.ort,props.language)}</h2>
        
          <div className='h-96 overflow-y-scroll pointer-events-auto mr-1'>

            <div>
              <table className="table table-sm table-auto	">
                {locationVeranstaltungen.map((veranstaltung, index) => (

                props.small?
                <VeranstaltungTerminSmall format={veranstaltung.format} name={veranstaltung.name} ort={veranstaltung.ort} choere={veranstaltung.choere} zeit={veranstaltung.zeit} id={veranstaltung._id} link={veranstaltung.link} datum={veranstaltung.datum} newDate={isSameDate(veranstaltung,index)}/>
                :  
                <VeranstaltungTermin format={veranstaltung.format} name={veranstaltung.name} ort={veranstaltung.ort} choere={veranstaltung.choere} zeit={veranstaltung.zeit} id={veranstaltung._id} link={veranstaltung.link} datum={veranstaltung.datum} newDate={isSameDate(veranstaltung,index)}/>
                  
                ))
                }
              </table>
            </div>

          </div>
          <ButtonLink className="btn pointer-events-auto btn-neutral font-roboto" to={props.ort && "veranstaltungsorte/"+GetOrtLink(props.ort)}>
         {props.language==="en"?
         
         <span>More about the place
         </span>:<span>Mehr zum Ort</span>
        }

          </ButtonLink>
        </div>
      </div>
    </React.Fragment>
  );

}
export default LocationPopup;