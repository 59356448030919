import React, {useEffect,useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './AppChorfest';
import reportWebVitals from './reportWebVitals';
import ChorInfo from './components/chorInfo';
import VeranstaltungDetails from './components/veranstaltungDetails';
import VeranstaltungOrtDetails from './components/veranstaltungsortDetails';
import CookieConsent from 'react-cookie-consent';

import {LanguageProvider} from './hooks/languageContext';
import { LikeProvider } from './hooks/likeContext';
import {ContentProvider} from './hooks/content-context';
import { CookieProvider } from './hooks/cookie-context';
import Team from './components/team';
import Newsletter from './components/footerDetailsNewsletter';
import Content from "./components/content";

import Veranstaltungen from './components/veranstaltungen';
import LikedVeranstaltungenPage from './components/likedVeranstaltungenPage';
import Formate from './components/formate';
import FooterDetails from './components/footerDetails';
import FooterDetailsProgramme from './components/footerDetailsProgramme';
import Impressionen from './components/impressionen';
import CookieConsentBanner from './components/cookieConsentBanner';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



const handleAccept = () => {

  console.log('Cookie-Zustimmung erteilt');

};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/choere/:id",
    element: <ChorInfo />
  },
  {
    path: "/veranstaltungen/:id",
    element: <VeranstaltungDetails />
  },
  {
    path: "/veranstaltungen/",
    element: <Veranstaltungen/>
  },
  {
    path: "/likedveranstaltungen/",
    element: <LikedVeranstaltungenPage/>
  },
  {
    path: "/team/",
    element: <Team/>
  }
  ,
  {
    path: "/newsletter/",
    element: <Newsletter/>
  }
  ,
  {
    path: "/veranstaltungsorte/:id",
    element: <VeranstaltungOrtDetails />
  }
  ,
  {
    path: "/formate/:id",
    element: <Formate />
  }
  ,
  {
    path: "/footer/:id",
    element: <FooterDetails />
  },
  {
    path: "/programme/",
    element: <FooterDetailsProgramme />

  },
  {
    path: "/impressionen/",
    element: <Impressionen />
  }
]);




ReactDOM.createRoot(document.getElementById("root")).render(
  <CookieProvider>
     <LanguageProvider>
      <LikeProvider>
<ContentProvider>

  <Content/>
 < CookieConsentBanner/>



    <RouterProvider router={router} />
    </ContentProvider>
    </LikeProvider>

    </LanguageProvider>
    </CookieProvider>
  );
//</React.StrictMode>!

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
