import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Headline from './headline';

function NavigationElement(props)  {

    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }


    return(
<div className='flex flex-row justify-center items-center gap-x-10'>
       
       <ButtonLink to={props.prev} className="z-10 btn btn-circle bg-gray hover:bg-neutral-800 border-none justify-center mt-8 mb-8 ml-8">
         <svg className="z-20 w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
         <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"/>
         </svg>
       </ButtonLink>

       <div className="flex-grow my-10 md:my-8
                   text-center">
                    <Headline>
         {props.name}
         </Headline>
       </div>

       
       <ButtonLink  to={props.next} className="btn btn-circle bg-gray hover:bg-neutral-800 border-none justify-center mt-8 mb-8 mr-8" >
         <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
         <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
         </svg>
       </ButtonLink>
</div>

    )
}

export default NavigationElement;