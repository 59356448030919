import React, {useEffect, useState, useContext} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import {GetVeranstaltungDatum,GetVeranstaltungFormat,GetVeranstaltungName,GetVeranstaltungBeschreibung, GetVeranstaltungChoere,GetVeranstaltungOrtKoordinaten,GetVeranstaltungOrt,GetVeranstaltungOrtBild,GetVeranstaltungDatumFormatA} from "../helperFunctions/helperFunctionsVeranstaltung";
import {GetChorDescription,GetChorName,GetChorImage,GetChorLink,GetChorLand} from "../helperFunctions/helperFunctionsChor"
import Navbar from './navbar';
import ChorCarousel from './chorCarousel';
import NavigationElement from '../elements/navigationElement';
import Map from './map';
import LikeButton from '../elements/likeButton';
import VeranstaltungenListe from './veranstaltungenListe';
import Header from '../components/header';
import Footer from '../components/footer';
import Headline from '../elements/headline.js';
import Skyline from '../grafiken/skyline.png';
import { LanguageContext } from '../hooks/languageContext';
import Paragraph from '../elements/paragraph.js';

const Veranstaltungen = ({props}) => {

    const {id} = useParams();
    const {state: {veranstaltungen}} = useContent();
    const {state: {formate}} = useContent();
    const [veranstaltungFilter,setVeranstalungFilter]=useState(0);
    const [activeFormat, setActiveFormat] = useState([0,0,0,0,0]);
    const [activeFormatData, setActiveFormatData] = useState(undefined);
    const [alleFormate, setFormate]=useState([]);
    const [language, setLanguage] = useContext(LanguageContext);

    const customSort = (array) => {

      var order;
      const orderDe= ['Singalong', 'Hannover klingt', 'Matinée', 'Lunch-Konzert',  'Konzert', 'Get together', 'NachtKlang','Gottesdienst'];
      const orderEng=['Singalong','Hanover sounds','Matinée','Lunch-Concert','Concert','Get together','NachtKlang','Service'];

      if(language==='en')
      {
        order=orderEng;
      }
      else
      {
        order=orderDe;
      }


      return array.sort((a, b) => {
        const indexOfA = order.indexOf(a);
        const indexOfB = order.indexOf(b);
    
        if (indexOfA === -1) return 1; // Move elements not in the order to the end
        if (indexOfB === -1) return -1;
    
        return indexOfA - indexOfB;
      });
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }

        useEffect(()=>{
          if(veranstaltungen)
          {
            let formate=[];
            veranstaltungen.forEach(e => {
              
              if(!formate.includes(GetVeranstaltungFormat(e,language)))
              {
                formate.push(GetVeranstaltungFormat(e,language));
              }
              
            });
            setFormate(customSort(formate));
          }
        },[veranstaltungen])

      function setFormat(index){
          let formate=[0,0,0,0,0];
          formate[index]=!activeFormat[index];

          if(formate[index]=true)
          {
            setActiveFormatData(alleFormate[index]);
          }

          setActiveFormat(formate);
      }

      useEffect(()=>{
        if (veranstaltungen) {

          let filterveranstaltungen=[];
          let activeFilter=false;
          veranstaltungen.forEach((e)=>{
            if(GetVeranstaltungFormat(e,language)===alleFormate[activeFormat.findIndex((e) => e === true)])
            {
              activeFilter=true;
              filterveranstaltungen.push(e);
            }
          })

          if(activeFilter)setVeranstalungFilter(filterveranstaltungen);
          else setVeranstalungFilter(veranstaltungen);
        }
      },[activeFormat,veranstaltungen])

     function GetFormatBeschreibung(formatName)
     {  
      let formatObject={};
      formate.forEach((formatElement)=>{

          if(formatName===formatElement.name[0].value)
          {

            formatObject=formatElement;
          }

          if(formatElement.name[1])
          {
            if(formatName===formatElement.name[1].value)
            {
              formatObject=formatElement;
            }
          }
      })


      let text="undefined";
        if(formatObject.beschreibung)
        {
            text=formatObject.beschreibung.de;
            if(language==="en" && formatObject.beschreibung.en)
                text=formatObject.beschreibung.en;
        }

        return text;
     }

    return (  

        <React.Fragment>


            <Header/>
            <div className="w-full h-full justify-center bg-yellow">

            <div className="relative w-full h-52 flex items-center justify-center bg-yellow ">
  {/* Image */}
  <img
    src={Skyline} // Replace with your image URL
    alt="Background Image"
    className="absolute inset-0 w-full h-full object-cover z-0"
  />

  {/* Text */}
  <div className="relative z-10 px-4 mb-12 h-full text-center text-6xl pt-28 text-gray font-roboto font-bold break-words">
  
  </div>
</div>

           

{/** Stadt oder Region Filter
        <div className=" bg-white w-full h-full sticky z-20 top-0">
      <div className="h-full text-center text-lg px-20 py-4 text-gray font-light font-roboto justify-center flex ">
        <div className='w-64'>
          <label className="cursor-pointer label">
            <span className="label-text font-bold text-lg">Stadt</span> 
              <input id="stadt" type="radio" name="radio-region" value={"stadt"} className="radio radio-info radio-lg -mr-1" checked={!isMapRegion} onClick={(e)=>{setIsMapRegion(0)}}/>
              <input id="region" type="radio" name="radio-region" value={"region"} className="radio radio-accent radio-lg -ml-1" checked={isMapRegion} onClick={(e)=>{setIsMapRegion(1)}}/>
            <span className="label-text font-bold text-lg ">Region</span> 
          </label>
        </div>
      </div>
    </div>
    */}


    
<div className="bg-white h-full text-center text-lg px-0 md:px-10 lg:px-20 py-4 text-gray font-normal font-roboto justify-center flex ">
      <div className="relative w-full md:w-3/4 z-10  h-full text-left text-3xl pt-3 text-gray font-roboto font-bold break-words">
        <span className='px-6'>
        {windowWidth<640? <span>{language=='de'?<span>Kalender</span>:<span>Calendar</span>}</span>:<span>{language=='de'?<span>Veranstaltungskalender</span>:<span>Events calendar</span>}</span>}
      </span>
    </div>
  </div>     

    <div className=" bg-white  w-full h-full sticky z-20 top-0 pt-16">

    
    <div className="h-full text-center text-lg px-20 py-4 text-gray font-light font-roboto justify-center flex ">

      

<div className='w-3/4 mt-2 mb-6 ml-auto mr-auto'>
 
  
<span className='pr-6'>Filter:</span>  <select  className="select select-warning w-full max-w-xs font-roboto justify-center" onChange={(e)=>{setFormat(e.target.value)}}>
<option  selected>{language=="de"? <span>Alle Formate</span>:<span>All formats</span>}</option>
{alleFormate.map((format,index)=>(
<option value={index}>
{format}
</option>
))}
</select>
<label className="cursor-pointer label">
    {/*alleFormate.map((format,index)=>
    (
      <React.Fragment>
      <input id={format} type="radio" name="radioformate" value={format} className="radio radio-warning radio-lg -mr-1" checked={activeFormat[index]} onClick={(e)=>{setFormat(index)}}/> 
      <span className="label-text font-bold text-lg">{format}</span> 
      </React.Fragment>
    )
    )*/}
  </label>
</div>
</div>

    <Headline >

   

  {
    activeFormatData==undefined ?
    <span >
      {language=="de"? <span>Alle Formate</span>:<span>All formats</span>}
    </span>
    :
    <span>
      {activeFormatData}
    </span>
  }
             </Headline>
      <div className="m-auto h-full text-center text-lg px-6 pt-4 text-gray font-light font-roboto w-full lg:w-4/6">
           {  activeFormatData==undefined?
    <span>
       {language=="de"? <span>Es gibt verschiedene Festivalformate. Du kannst die Veranstaltungen nach Formaten filtern.</span>:<span>There are different festival formats. You can filter the events by format.</span>}

    </span>
    :
    <span>
{activeFormatData &&
              <div className='w-full font-light font-roboto md:w-6/6 lg:w-6/6'>
    <Paragraph className='text-left'>
      
    <PortableText value={GetFormatBeschreibung(activeFormatData)}/>
    </Paragraph>
    </div>
  }
{/**/}

    
    {/**{<PortableText value={GetFormatBeschreibung(GetVeranstaltungFormatObject(veranstaltung), language)}/>} */}
    </span>
    }
           
              </div>



    </div>
 
</div>


{veranstaltungen &&
<React.Fragment>
<div className="flex flex-col items-center justify-center mt-20">
      <div className="flex flex-col items-center justify-center">
        <div className="flex justify-center w-full">
          <div className="flex items-center mr-2 md:mr-8">
            <div className="bg-gray w-6 h-6 mr-2 ml-2 md:mr-4 md:ml-4"></div>
            <p className="text-base font-roboto">{windowWidth>=768?"Hannover Stadt":"Stadt"}</p>
          </div>
          <div className="flex items-center mr-2 md:mr-8">
            <div className="bg-blue w-6 h-6 mr-2 ml-2 md:mr-4 md:ml-4"></div>
            <p className="text-base font-roboto">{windowWidth>=768?"Hannover Stadt, Outdoor":"Outdoor"}</p>
          </div>
          <div className="flex items-center mr-2 md:mr-8">
            <div className="bg-green w-6 h-6 mr-2 ml-2 md:mr-4 md:ml-4"></div>
            <p className="text-base font-roboto">{windowWidth>=768?"Hannover Region und Niedersachsen":"Region"}</p>
          </div>
        </div>
      </div>
    </div>

<div className="bg-white bg-opacity-100 overflow-hidden h-max w-full justify-center items-center flex relative z-30 py-8" >
          <VeranstaltungenListe language={language} width="w-full md:w-4/5 lg:3/5 xl:w-2/3 2xl:w-2/3" height="h-max" veranstaltungen={veranstaltungFilter} />
    </div>
    </React.Fragment>
  }
    <Footer/>
  
        </React.Fragment>
    );
}

export default Veranstaltungen;