import React, { useEffect, useRef, useContext } from 'react';
import { Link,useNavigate } from "react-router-dom";
import { useWindowScroll, useWindowSize } from 'react-use';
import { LanguageContext } from '../hooks/languageContext';
import gsap from 'gsap';

const ScrollButton = (footerRef) => {
  const buttonContainerRef = useRef(null);
  const scrollTopButtonRef = useRef(null);
  const scrollToFooterButtonRef = useRef(null);
  const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const [language, setLanguage] = useContext(LanguageContext);

  useEffect(() => {
    const scrollTopButton = scrollTopButtonRef.current;
    const scrollToFooterButton = scrollToFooterButtonRef.current;
    const buttonContainer = buttonContainerRef.current;
    const footer = document.querySelector('footer');
    //const footer = footerRef.current;

    if (buttonContainer && scrollTopButton && scrollToFooterButton && footer) {
      const footerPosition = footer.getBoundingClientRect();
      const buttonContainerPosition = buttonContainer.getBoundingClientRect();

      if (footerPosition.top < height) {
        gsap.to(buttonContainer, {
          duration: 0.3,
          bottom: `${height - footerPosition.top + 20}px`,
          ease: 'power3.out',
        });
      } else {
        gsap.to(buttonContainer, {
          duration: 0.9,
          bottom: y > 500 ? '20px' : '-100px',
          ease: 'power3.out',
        });
      }
    }
  }, [y, height,footerRef]);

  return (
    <div ref={buttonContainerRef} className="fixed bottom-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center w-auto space-x-4 z-40">
      <Link to={"../footer/spenden"}>      
      <button ref={scrollTopButtonRef} className="p-4 bg-yellow text-white rounded-full shadow-lg">
        {language === "en"? <span>Donate</span>:<span>Spenden</span>}
      </button>
      </Link>

        <Link to={"https://jugendchorfestival.reservix.de/events"}>
      <button ref={scrollToFooterButtonRef} className="p-4 bg-orange text-white rounded-full shadow-lg">
        Tickets
      </button>
      </Link>
    </div>
  );
};

export default ScrollButton;
