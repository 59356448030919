import React, {useContext} from 'react';
import Switcher6 from '../elements/switcher6';
import { LanguageContext } from '../hooks/languageContext';

function LanguageSwitch(props) {

    const [language, setLanguage] = useContext(LanguageContext);

    function HandleLanguageChange(e) {
      setLanguage(e);        
  };

    return(
       

                <div className='w-64 md:w-64 flex '>
                  <div className={`w-28 text-center ml-6 text-gray   text-base 
                  ${
                    language ==="en"? 'font-roboto text-gray' : 'font-bold text-yellow'
                  }`}>
                    deutsch
                  </div>
                  <div className='flex-1 text-center pointer-events-auto'>
                    <Switcher6 language={language} onSelectLanguage={(e)=>HandleLanguageChange(e)}/>
                  </div>
                  <div className={`w-28 text-center mr-6  text-gray font-roboto text-base 
                  ${
                    language ==="en"? 'font-bold text-yellow' : 'font-roboto text-gray'
                  }`
                }>
                    english
                  </div>
                  
              </div>
          
    );

}


export default LanguageSwitch;