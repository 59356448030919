import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);

export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }

      export const GetMemberDescription=(member,language)=>{
        let text="More infos coming soon.";
        if(member.beschreibung)
        {
          if(language === "de")
            text=member.beschreibung.de;
          if(language==="en")
            text=member.beschreibung.en;

        }

        return text;
      }
  
      export const GetMemberName=(member, language)=>
      {
        let text="undefined";
        if(member.name)
        {
          text=member.name;
        }
        return text;
      }

      export const GetMemberMail=(member)=>
      {
        let text="undefined";
        if(member.mail)
        {
          text=member.mail;
        }
        console.log(text);
        return text;
      }


      export const GetMemberPosition=(member, language)=>
      {
        let text="undefined";
        if(member.position)
        {
          text=member.position;
        }
        return text;
      }

  
      export const GetMemberImage=(member, width, height)=>
      {
        return getSanityImageUrl(member.bild.asset)
        .width(width).height(height).focalPoint(member.bild.hotspot.x, member.bild.hotspot.y)
        .fit("crop").crop("focalpoint").url();
      }

     