import React, {useEffect, useState, useContext} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import NavigationElement from '../elements/navigationElement';
import {GetFormatBeschreibung, GetFormatName} from "../helperFunctions/helperFunctionsFormate"
import Header from '../components/header';
import Footer from '../components/footer';

import { LanguageContext } from '../hooks/languageContext';

const Formate = ({props}) => {

    const {id} = useParams();
    const {state: {formate}} = useContent();
    const [format,setFormat] =useState(null);
    const [formatIndex,setFormatIndex]=useState(0);
    const [language, setLanguage] = useContext(LanguageContext);


    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }


      useEffect(()=>{
        if(formate && id) 
          {
            console.log(id);
            let activeFormat=GetFormatbyLink(id);
            setFormat(activeFormat);
          }
    },[formate, id])

      function GetNextFormat(){
        let index=0;
        if (formatIndex<formate.length-1)index=formatIndex+1;
        return formate[index].link.current;
      }
  
      function GetPrevFormat(){
        let index=formate.length-1;
        if (formatIndex>0)index=formatIndex-1;
        return formate[index].link.current;
      }

      useEffect(()=>{
        if(formate)
        {
          GetFormatbyLink(id);
        }
      },[formate])

      function GetFormatbyLink(formatid)
    {
        var formatFound=null;
        for(let i=0; i<formate.length;i++)
        {
          if(formate[i].link.current===formatid)
          {
            formatFound = formate[i];
            setFormatIndex(i)
            console.log(formatFound);
            setFormat(formatFound);
              break;
          }
        }
        return formatFound;
    }


    return (  

        <React.Fragment>

            <Header/>

            <div className="w-full h-full justify-center bg-yellow">
            <div className="h-52"/>
            <NavigationElement 
             next={"../formate/"+GetNextFormat()}
             prev={"../formate/"+GetPrevFormat()}
             name={format && GetFormatName(format, language)}
             />

            

    

    <div className=" bg-white  w-full h-full sticky z-20 top-0">
      <div className="h-full text-center text-lg px-20 py-4 text-gray font-light font-roboto justify-center flex ">
        <div className='w-3/4 mt-14'>
        {format && <PortableText value={GetFormatBeschreibung(format, language)}/>}
        
        </div>
      </div>
    </div>
 
</div>
      <Footer/>
        </React.Fragment>
    );
}

export default Formate;