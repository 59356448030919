import React, {useState,useRef, useEffect} from 'react';
import Headline from './headline';
import Paragraph from './paragraph';
import { PortableText } from '@portabletext/react';
import LocationPopup from '../components/locationPopup';
import Map from '../components/map';

import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';


const MapSection = (props) => {
  var stadtMapLocation = JSON.stringify({ "center": [9.732273577692233, 52.37633862264693], "zoom": 12 });
  var regionMapLocation = JSON.stringify({ "center": [9.302273577692233, 52.37633862264693], "zoom": 9 });

  const [isMapRegion, setIsMapRegion] = useState(1);
  const [mapOrt, setMaport] = useState(0);
  const [mapRegion, setMapRegion] = useState(JSON.parse(regionMapLocation));

  const mapInsertRef =useRef(null);
  const mapInsertContainerRef =useRef(null);

  useEffect(()=>{

    gsap.from(mapInsertRef.current,{
      x: "-25%",
      ease: 'none',
      scrollTrigger: {
        trigger: mapInsertContainerRef.current,
        start: 'top bottom',
        end: 'top center',
        scrub: true,
        markers:false
      },
      

    })
  },[])

  return (
    <div className='bg-white w-full h-full'>
    <div className="items-center flex flex-col">
      <Headline className="pt-28">
        {props.headline}
      </Headline>
      <Paragraph className="text-center">
      {props.text && <PortableText value={props.text} />}
      </Paragraph>
    </div>

    <div className="lg:h-24"></div>
    <div className=" bg-white w-full h-full sticky z-20 top-0">
      <div className="h-full text-center text-lg px-20 py-4 text-gray font-light font-roboto justify-center flex ">
        <div className='w-64'>
          <label className="cursor-pointer label">
            <span className="label-text font-bold text-lg">{props.language === "en" ? <span>City</span> : <span>Stadt</span>}</span>
            <input id="stadt" type="radio" name="radio-region" value={stadtMapLocation} className="radio radio-info radio-lg -mr-1" checked={!isMapRegion} onClick={(e) => { setMapRegion(JSON.parse(e.currentTarget.value)); setIsMapRegion(0) }} />
            <input id="region" type="radio" name="radio-region" value={regionMapLocation} className="radio radio-accent radio-lg -ml-1" checked={isMapRegion} onClick={(e) => { setMapRegion(JSON.parse(e.currentTarget.value)); setIsMapRegion(1) }} />
            <span className="label-text font-bold text-lg ">{props.language === "en" ? <span>Region</span> : <span>Umland</span>}</span>
          </label>
        </div>
      </div>
    </div>

    {props.mapConsent === 'true' ?
      <div ref={mapInsertContainerRef} className="w-full h-screen75 md:h-fullscreen sticky z-0 top-0 md:relative">
        <div  ref={mapInsertRef}className="pointer-events-none hidden md:absolute md:inset-0 bg-opacity-0 h-full w-full justify-left items-center md:flex z-10 p-8" id="CSSProgress">
          <LocationPopup width="w-96" height="h-screen75" ort={mapOrt} small={true} language={props.language}/>
        </div>
          <Map region={mapRegion} setOrt={setMaport} oneLocationOnly={false} />
      </div>
      :
      <div className='w-full h-96 bg-black sticky z-0 top-0 md:relative text-2xl font-roboto font-bold text-center pt-44'>
        <p>
          Please Enable cookies to see Map+Navigation Features
        </p>
        <p className='mt-8'>
          <btn onClick={() => props.reShowBanner(true)} className="link link-hover">Change Cookie policy</btn>
        </p>
      </div>
    }

  </div>
  )
}

export default MapSection