import React, { useState, useContext, useRef, useEffect } from 'react';
import { GetChorName, GetChorImage, GetLink, GetChorLand } from "../helperFunctions/helperFunctionsChor";
import { Link } from "react-router-dom";
import { LanguageContext } from '../hooks/languageContext';
import Paragraph from '../elements/paragraph';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Carousel = ({ choere, screenWidth }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [language, setLanguage] = useContext(LanguageContext);
  const choereRef = useRef([]);

  useEffect(() => {
if(choere)
{
    gsap.set(choereRef.current, { scale: 0 });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: choereRef.current[0],
        start: 'top 100%',
        end: 'top 80%',
        scrub: true,
        markers: false, // Remove this in production
      },
    });

    // Animation for the first set of buttons (scale from 0 to 1)
    tl.to(choereRef.current, {
      scale: 1,
      stagger: 0.5, // Adjust the stagger effect as needed
    });
    
    setTimeout(()=>{ScrollTrigger.refresh(true);}, 100);
  }
  }, [choereRef,choere]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex === choere.length - getNrChoereToShow() ? prevIndex : prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? prevIndex : prevIndex - 1
    );
  };

  const getNrChoereToShow = () => {
    let nrChoere = 1;
    if (screenWidth > 640) nrChoere = 2;
    if (screenWidth > 1024) nrChoere = 3;
    if (choere.length === 1) {
      nrChoere = 1;
    }
    
    return nrChoere;
  }

  const getChorLayout = () => {
    let classname = "w-full px-6 h-full text-center";
    if (screenWidth > 640) classname = "w-1/2 px-6 h-full text-center";
    if (screenWidth > 1024) classname = "w-1/3 px-6 h-full text-center";
    if (choere.length === 1) classname = "w-full px-6 h-full text-center";

    return classname;
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex overflow-hidden">

{screenWidth > 1024 &&
        <div className="grid grid-rows-3 grid-flow-col w-18 h-18">

          <div className='w-18 h-18'><span className='h-18 w-18'></span></div>

          <button className="self-baseline align-middle pr-8 text-gray hover:text-yellow hover:disabled:text-gray disable disabled:opacity-40 disabled:cursor-not-allowed" onClick={handlePrev} disabled={currentIndex === 0} >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>

          <div className='w-18 h-18'><span className='h-18 w-18'></span></div>
        </div>
  }
        {choere.slice(currentIndex, currentIndex + getNrChoereToShow()).map((chor, index) => (
          <div
            key={index}
            className={getChorLayout()}

          >
            <Link to={"../choere/" + GetLink(chor)}>
              <img ref={(el) => (choereRef.current[index] = el)} src={GetChorImage(chor, 320, 320)} alt={`Image ${index}`} className="w-80 h-80 mb-2 md:mb-8 object-cover object-center mask mask-circle bg-gray-100" />
            </Link>
            
            <Link to={"../choere/" + GetLink(chor)}>
              <Paragraph>{GetChorName(chor, language)}</Paragraph>
            </Link>
            <h2 className="text-gray font-light font-roboto tracking-wider text-lg">{GetChorLand(chor, language)}</h2>
            <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-2 mb-2 md:mt-6 md:mb-4"></span>

          </div>
        ))}

{screenWidth > 1024 &&
        <div className="grid grid-rows-3 grid-flow-col w-18 h-18">
          <div className='w-18 h-18'><span className='h-18 w-18'></span></div>
          <button className="self-baseline pl-8 w-full text-gray hover:text-yellow hover:disabled:text-gray disable disabled:opacity-40 disabled:cursor-not-allowed" onClick={handleNext} disabled={currentIndex === choere.length - getNrChoereToShow()} >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
          <div className='w-18 h-18'><span className='h-18 w-18'></span></div>
        </div>
}
      </div>



      {screenWidth <= 1024 &&
      <div className="join grid grid-cols-3">
        <button className="self-baseline pr-8 text-gray hover:text-yellow hover:disabled:text-gray disable disabled:opacity-40 disabled:cursor-not-allowed" onClick={handlePrev} disabled={currentIndex === 0} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
        <span className='w-18 h-18'></span>
        <button className="w-18 h-18 pl-8 text-gray hover:text-yellow hover:disabled:text-gray disable disabled:opacity-40 disabled:cursor-not-allowed" onClick={handleNext} disabled={currentIndex === choere.length - getNrChoereToShow()} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>
}

    </div>
  );
};

export default Carousel;