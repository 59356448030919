
import React, {useEffect, useState, useRef} from 'react';
import { useContent } from '../hooks/content-context';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { Link } from "react-router-dom";
import {GetOrtName,GetOrtLink} from "../helperFunctions/helperFunctionsVeranstaltungsOrt";
import reactElementToJSXString from 'react-element-to-jsx-string';

mapboxgl.accessToken = 'pk.eyJ1IjoicmFib3R3b2xmZiIsImEiOiJjbGpxemZseTMwMGh6M2tscWNnM2p1dDNlIn0.qOyloVyygpDTgQ94RszIKg';


const Map=(props)=>{

    const { state: { veranstaltungsorte } } = useContent();
const mapContainer = useRef(null);
const map = useRef(null);
const [lng, setLng] = useState(9.302273577692233);
const [lat, setLat] = useState(52.37633862264693);
const [zoom, setZoom] = useState(9);
const [mapMarkers, setMapMarkers] = useState([]);
const [mapMarkersPaths, setMapMarkersPaths] = useState([]);
const [activeMarker, setActiveMarker]=useState(-1);

function ButtonLink({ to,className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

function setMarkerFromLatLng(lng, lat){
    veranstaltungsorte.forEach((veranstaltungsort,index) => {

        if(veranstaltungsort.koordinaten["lng"]===lng && veranstaltungsort.koordinaten["lat"]===lat)
        {
            setActiveMarker(index);
            props.setOrt(veranstaltungsorte[index]);
            
            mapMarkersPaths.forEach((markerPath,markerIndex)=>{

                if(markerIndex===index)
                {
                    markerPath.setAttribute("fill", "#ffdf00");
                }
                else
                {
                    markerPath.setAttribute("fill", "#444444");
                }
            })
        }
        
    });

}

useEffect(() => {
    if(map.current && props.region )
    {
            map.current.flyTo(props.region);
            
    }
},[props.region, ])

useEffect(()=>{
    if(props.activeMarker && mapMarkersPaths )
    {
        setMarkerFromLatLng(props.activeMarker.lng,props.activeMarker.lat);
        
    }
},[props.activeMarker,mapMarkersPaths,])

useEffect(()=>{


    if(mapMarkers.length>0)
    {
    var pathElements=[];
    mapMarkers.forEach((marker,index)=>{

        let element = marker.getElement();
        let svg = element.getElementsByTagName("svg")[0];
        let path = svg.getElementsByTagName("path")[0];
        pathElements.push(path);
        element.style.cursor = "pointer";
        
    })
    setMapMarkersPaths(pathElements);
    
    if(props.activeMarker)
    {
        setMarkerFromLatLng(props.activeMarker.lng,props.activeMarker.lat);
    }
    else {
        let id;
        veranstaltungsorte.forEach((ort,index)=>
        {
            if("infopointkuenstlerhaus"===ort.link.current)
            {
                id=index;
            }
        })

        let activeMarker=id;
        setActiveMarker(activeMarker);
        props.setOrt(veranstaltungsorte[activeMarker]);
    }

    }

},[mapMarkers,])

useEffect(() => {
    if(mapMarkersPaths.length>0 )
    {
    
    mapMarkersPaths.forEach((markerPath,index)=>{

        if(index===activeMarker)
        {
            markerPath.setAttribute("fill", "#ffdf00");
        }
        else
        {
            markerPath.setAttribute("fill", "#444444");
        }
    })
    }
},[activeMarker,])


function getMapHtml(i){
    return <div>
    <h1 className='font-roboto font-bold text-black'>{GetOrtName(veranstaltungsorte[i])}</h1>
    <ButtonLink className='btn pointer-events-auto btn-neutral font-roboto' to={'veranstaltungsorte/'+GetOrtLink(veranstaltungsorte[i])}>
    Mehr zum Ort
    </ButtonLink>
    </div>
}

useEffect(() => {

    if(veranstaltungsorte && props.oneLocationOnly===true )
    {
        //if (map.current) return;

        map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [lng, lat],
        zoom: zoom,
        //scrollZoom: false,
        //dragPan:false,
        dragRotate: false,
        //doubleClickZoom: false,
        //interactive: false,
        cooperativeGestures: true
        });

        let marker=new mapboxgl.Marker({ color: '#ffdf00', rotation: 0, cursor: "pointer" })
        .setLngLat([props.activeMarker.lng,props.activeMarker.lat])
        //.setPopup(new mapboxgl.Popup({offset: 25}).setText(veranstaltungsorte[i].name[0].value))
        //.setPopup(new mapboxgl.Popup().setHTML(reactElementToJSXString(buttonLinkHtml)))
        .addTo(map.current);



       // setMarkerFromLatLng(props.activeMarker.lng,props.activeMarker.lat);
        

    }

    if(veranstaltungsorte && props.oneLocationOnly===false)
    {
        if (map.current) return;

        map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [lng, lat],
        zoom: zoom,
        //scrollZoom: false,
        //dragPan:false,
        dragRotate: false,
        //doubleClickZoom: false,
        //interactive: false,
        cooperativeGestures: true
        });

        //map.current.scrollZoom.disable();
        var allMarker=[];

        

        for(let i=0;i<veranstaltungsorte.length;i++)
        {
            if(veranstaltungsorte[i].koordinaten)
            {
                let popup = new mapboxgl.Popup()
                .setText(GetOrtName(veranstaltungsorte[i]));

               

            let buttonLinkHtml=
                <div>
                    <h1 class='card-title font-roboto font-bold text-gray m-6'>{GetOrtName(veranstaltungsorte[i])}</h1>    
                    <a  onClick={(e) => {
                        e.preventDefault();
                        window.navigation.push('/veranstaltungsorte/'+GetOrtLink(veranstaltungsorte[i]));
                    }}
                    class='outline-none' href={'/veranstaltungsorte/'+GetOrtLink(veranstaltungsorte[i])} ><span class='btn pointer-events-auto btn-neutral font-roboto mx-6 mb-6'>Mehr zum Ort</span>
                    </a>      
                </div>;

                let marker=new mapboxgl.Marker({ color: '#444444', rotation: 0, cursor: "pointer" })
                .setLngLat([veranstaltungsorte[i].koordinaten["lng"], veranstaltungsorte[i].koordinaten["lat"]])
                .setPopup(new mapboxgl.Popup({offset: 25}).setText(veranstaltungsorte[i].name[0].value))
                .setPopup(new mapboxgl.Popup().setHTML(reactElementToJSXString(buttonLinkHtml)))
                .addTo(map.current);
                

                marker.getElement().addEventListener('click', () => {
                    props.setOrt(veranstaltungsorte[i]);
                    setActiveMarker(i);
                });

                allMarker.push(marker);
            }
            else{
                allMarker.push(new mapboxgl.Marker());
            }
        };

        setMapMarkers(allMarker);
    }
    
    },[veranstaltungsorte,props.activeMarker]);

    function flyTo(lat,lng){
       map.flyTo({
            center: [lat,lng],
            zoom: 16,
            bearing: 0,
            speed: 0.35, 
            curve: 1.42, // change the speed at which it zooms out
            // This can be any easing function: it takes a number between
            // 0 and 1 and returns another number between 0 and 1.
            easing: function (t) {
            return t;
            },
            // this animation is considered essential with respect to prefers-reduced-motion
            essential: true
            })
        map.on('moveend', function(e){
         });
    }

    return(
        <div className='h-full w-full'>
            
                        <div className="h-full w-full" ref={mapContainer} data-scroll-enable-touch-speed data-scroll data-scroll-speed="-0.1"> </div>
            
                        </div>
    )

}
export default Map;