import React, { useContext, useEffect } from 'react';
import LikeButton from './likeButton';
import { Link } from "react-router-dom";
import { GetVeranstaltungOrtLink, GetVeranstaltungDatum, GetVeranstaltungName, GetVeranstaltungDatumFormatA, GetVeranstaltungZeit } from "../helperFunctions/helperFunctionsVeranstaltung";
import { GetChorName, GetLink } from '../helperFunctions/helperFunctionsChor';
import TableLine from './tableLine';
import { LanguageContext } from '../hooks/languageContext';
import TicketImg from '../grafiken/ticket_882696.svg';
function VeranstaltungTermin(props) {
  const [language, setLanguage] = useContext(LanguageContext);
  function ButtonLink({ to, className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

  function GetColorCode()
  {
    switch(props.stadt)
    {
      case "Stadt":return "text-gray"; break;
      case "Region":return "text-green"; break;
      case "Outdoor":return "text-blue"; break;
      default: return "text-gray";
    }

  }

  return (
    <React.Fragment key={props.id}>

      {props.newDate ?
        null :
        <thead className='sticky top-0  z-30'>

          <tr className=''>
            <td  ><TableLine className='bg-white mt-6 font-robotserif text-2xl text-gray'><br />{props.datum}</TableLine></td>
          </tr>
        </thead>
      }

      <tbody>
        <tr className=''>
          <td className=' font-roboto'><span className='p-1 '></span><TableLine className={`${GetColorCode()} hover:text-orange`}>{props.zeit}</TableLine></td>


          <td className='font-roboto '>
              <Link to={"../veranstaltungen/" + props.link}>
                <td >
                <TableLine className={`${GetColorCode()} hover:text-orange`}>
                    {
                      props.name
                    }
                  </TableLine>
                </td>
              </Link>
          </td>
          
          <td>
            {props.ticketlink ?
            <ButtonLink className="w-6 h-6 md:w-12 md:h-12 pointer-events-auto btn-neutral text-base p-0 md:p-2 m-0 bg-opacity-0 text-gray hover:text-orange hover:bg-opacity-0 border-none font-roboto" 
            to={props.ticketlink}>
              {/** {"/footer/tickets"}*/}
              <img className="w-6 h-6" src={TicketImg}></img>
            </ButtonLink>
            :
            <div className="w-6 h-6 md:w-12 md:h-12 pointer-events-none btn-neutral text-base p-0 md:p-2 m-0 bg-opacity-0 text-gray  border-none font-roboto" >
              free
            </div>
            }
          </td>
          <td>
            <LikeButton id={props.id} />
          </td>
          
        </tr>

      </tbody>
    </React.Fragment>
  )


}

export default VeranstaltungTermin;