import React, {useEffect, useState} from 'react';
export const LikeContext = React.createContext();


function LikeProvider({children}){

    const [likes, setLikes] = useState([0,0]);
    const [getLocalStorageLikes, setGetLocalStorageLikes] = useState(false);

    useEffect(()=>{
        if(getLocalStorageLikes)
        {
          localStorage.setItem('likes', JSON.stringify(likes));
        }
      },[likes])
  
      useEffect(()=>{
        var likes = JSON.parse(localStorage.getItem('likes'));
        setLikes(likes);
        setGetLocalStorageLikes(true);
      },[])

      return <LikeContext.Provider value={[likes, setLikes]}>{children}</LikeContext.Provider>
  
  

}

function useLikes() {
    const context = React.useContext(LikeContext)
    if (context === undefined) {
      throw new Error('useLanguage must be used within a LanguageProvider')
    }
    return context
  }
  
  export {LikeProvider, useLikes}