import React, {useEffect, useState, useContext} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import {GetVeranstaltungDatum,GetVeranstaltungTicketLink,IsVeranstaltungCity,GetVeranstaltungName,GetVeranstaltungOrt,GetVeranstaltungOrtBild,GetVeranstaltungOrtLink,GetVeranstaltungLink, GetVeranstaltungZeit, GetVeranstaltungDatumFormatA} from "../helperFunctions/helperFunctionsVeranstaltung";
import {GetChorDescription,GetChorName, GetChorImage,GetChorLink, GetChorLand} from "../helperFunctions/helperFunctionsChor"
import { textFormat } from '../helperFunctions/helperFunctionsTextFormat.js';
import Navbar from './navbar';
import TerminElement from '../elements/terminElement';
import NavigationElement from '../elements/navigationElement';
import VeranstaltungTermin from '../elements/VeranstaltungTermin';
import Header from '../components/header';
import Footer from '../components/footer';
import { LanguageContext } from '../hooks/languageContext';
import Paragraph from '../elements/paragraph';
import Headline from '../elements/headline';
import SubHeadline from '../elements/subHeadline';


const ChorInfo = ({data}) => {

    const {id} = useParams();
    const {state: {choere}} = useContent();
    const {state: {choereRegional}} = useContent();
    const {state: {veranstaltungen}} = useContent();
    const {state: {specialGuests}} = useContent();
    const [isregionalChor,setIsRegionalChor]=useState(null);
    const [chorType,setChorType]=useState(null);
    const [chor,setChor]=useState(0);
    const [chorIndex,setChorIndex]=useState(0);
    const [veranstaltungenChor,setVeranstaltungenChor]=useState(0);
    const [language, setLanguage] = useContext(LanguageContext);
    const navigate = useNavigate();

    const sanityImageBuilder = imageUrlBuilder(sanityClient);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); 


    function getSanityImageUrl(source) {
        return sanityImageBuilder.image(source)
      }

      useEffect(()=>{
        console.log("CHORINFO LANGUAGE:"+language);
      }
    ,[language])

    useEffect(()=>{
      if(choere && choereRegional && specialGuests && id && veranstaltungen) 
        {
       
          let filterVeranstaltungenChor=[];
          let activeChor=GetChorByLink(id);
          veranstaltungen.forEach(veranstaltung => {
         
            if(veranstaltung.choere)
            {
              veranstaltung.choere.forEach(chor=>{
                if(chor._id===activeChor._id)
                {
                  filterVeranstaltungenChor.push(veranstaltung);
                }
              })
            }
          });

          setVeranstaltungenChor(filterVeranstaltungenChor);
          setChor(activeChor);
        }
  },[id,choere,choereRegional,specialGuests,veranstaltungen])

  function ButtonLink({ to,className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }
    function GetChorByLink(linkid)
    {
        var chorfound=null;
        for(let i=0; i<choere.length;i++)
        {
          if(choere[i].link.current===linkid)
          {
              chorfound= choere[i];
              setChorIndex(i);
              setChorType("international")
              break;
          }
        }

        for(let i=0; i<choereRegional.length;i++)
        {
          if(choereRegional[i].link.current===linkid)
          {
              chorfound= choereRegional[i];
              setChorIndex(i);
              setChorType("regional")
              break;
          }
        }

        for(let i=0; i<specialGuests.length;i++)
        {
          if(specialGuests[i].link.current===linkid)
          {
              chorfound= specialGuests[i];
              setChorIndex(i);
              setChorType("special")
              break;
          }
        }

        

        return chorfound;
    }

    //ADD SPECIAL GUESTS
    function GetNextChor(){
      let index=0;
      let link;
      if(chorType==="regional")
      {
        if (chorIndex<choereRegional.length-1)index=chorIndex+1;
        link =choereRegional[index].link.current;
        return link;
        
      }
      if(chorType==="international"){
        if (chorIndex<choere.length-1)index=chorIndex+1;
        link= choere[index].link.current;
        return link;
      }
      if(chorType==="special"){
        if (chorIndex<specialGuests.length-1)index=chorIndex+1;
        link= specialGuests[index].link.current;
        return link;
      }
      return link;
    }

        //ADD SPECIAL GUESTS
    function GetPrevChor(){
      let link;
      if(chorType==="regional")
      {
        let index=choereRegional.length-1;
        if (chorIndex>0)index=chorIndex-1;
        link= choereRegional[index].link.current;
        return link;
      }
      if(chorType==="international")
      {
        let index=choere.length-1;
        if (chorIndex>0)index=chorIndex-1;
        link= choere[index].link.current;
        return link;
      }
      
      if(chorType==="special")
      {
        let index=specialGuests.length-1;
        if (chorIndex>0)index=chorIndex-1;
        link=specialGuests[index].link.current;
        return link;
      }
      return link;
    }

    function isSameDate(veranstaltung,index){
      if(index===0) return false;
      if(GetVeranstaltungDatumFormatA(veranstaltungenChor[index - 1]) === GetVeranstaltungDatumFormatA(veranstaltung))
      return true;
      else return false;
    }

  return (
    <React.Fragment>
      <div className=' md:mt-0 bg-white h-24 md:h-0'></div>
      <Header/>
      {chor ?
      <div>

<div className='relative bg-yellow h-screen/2 md:h-2/6'>

                <img  
                className="w-full h-full md:w-full  object-cover" 
                alt={GetChorName(chor, language)}
              src={GetChorImage(chor,screenWidth<640? 500:1800,screenWidth<640? 600:600)}
                />
            </div>
        
        <div className={"bg-yellow"}>

        <NavigationElement 
          next={"../choere/"+GetNextChor()}
          prev={"../choere/"+GetPrevChor()}
          name={GetChorName(chor,language)}
          />


            <div className="px-1 md:px-4 lg:px-20 mx-auto text-center">
          <Paragraph className="text-left">
              <PortableText
                value={GetChorDescription(chor,language)}
                components={textFormat}
              />
          </Paragraph>
{GetChorLink(chor) &&
            <div className="w-full  px-4 lg:px-20 mx-auto mt-7">

         <div className="h-full w-full text-center text-xl  md:text-xl lg:text-xl pt-4 text-gray font-robotserif">
            {language ==="en"? <span>{chorType==="special"? <span>My webpage:</span>:<span>Our webpage:</span>}</span>:<span>{chorType==="special"? <span>Meine Webseite:</span>:<span>Unsere Webseite:</span>} </span>}

               <a href={GetChorLink(chor)}>{GetChorLink(chor)}</a>
               </div>
            </div>
          }
          </div>
          <div className='h-16'/>
        </div>
       { veranstaltungenChor && veranstaltungenChor.length>0 &&
      <React.Fragment>
      <div className="justify-center mt-8 mb-2 md:mt-12 md:mb-6
                        text-center ">                    
             {language ==="en"? <Headline>{chorType==="special"? <span>Meet me here:</span>:<span>You can hear us here:</span>}</Headline>:<Headline>{chorType==="special"? <span>Hier kannst Du mich treffen:</span>:<span>Hier kannst Du uns hören:</span>}</Headline>}
      </div>

      <div className="justify-center mt-2 md:mt-12 mb-6
                        text-center text-2xl lg:text-6xl text-gray font-roboto font-bold">
          <div className={"w-full md:w-4/6 h-max card bg-white max-h-max ml-auto mr-auto shadow-xl z-20"}>
            <div className="card-body h-full ">
              <div className='h-full overflow-y-auto pointer-events-auto'>
                <table className="table table-sm ">
                  {veranstaltungenChor && veranstaltungenChor.map((veranstaltung, index) => (
                    <VeranstaltungTermin ticketlink={GetVeranstaltungTicketLink(veranstaltung)} screenWidth={screenWidth} name={GetVeranstaltungName(veranstaltung,language, true)} zeit={GetVeranstaltungZeit(veranstaltung)} ort={GetVeranstaltungOrt(veranstaltung)} id={veranstaltung._id} datum={GetVeranstaltungDatumFormatA(veranstaltung)} newDate={isSameDate(veranstaltung, index)} veranstaltung={veranstaltung} link={GetVeranstaltungLink(veranstaltung)} stadt={IsVeranstaltungCity(veranstaltung)} />
                  ))}
                </table>
              </div>
            </div>
          </div>
      </div>
      <div className='h-40'/>
      </React.Fragment>
      }
                

        </div>
        :
        <div>{/** No Chor Found */}</div>
      }
      <Footer/>
    </React.Fragment>
  )
}
export default ChorInfo;