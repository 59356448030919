import React, {useEffect, useState,useRef} from 'react';

import { Link,useNavigate } from "react-router-dom";
import sanityClient from "../client.js";
import { useContent } from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import Headline from '../elements/headline.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Sponsors = (props) => {

    const {state: {sponsors}} = useContent();
    const sanityImageBuilder = imageUrlBuilder(sanityClient);
    const sponsorsRef = useRef([]);

   const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }


      useEffect(() => {
console.log(sponsors);
        gsap.set(sponsorsRef.current, { scale: 0 });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: sponsorsRef.current[0],
            start: 'top 100%',
            end: 'top 80%',
            scrub: true,
            markers: false, // Remove this in production
          },
        });
  
        // Animation for the first set of buttons (scale from 0 to 1)
        tl.to(sponsorsRef.current, {
          scale: 1,
          stagger: 0.5, // Adjust the stagger effect as needed
        });
        
        setTimeout(()=>{ScrollTrigger.refresh(true);}, 100);
      }, [sponsorsRef]);

    return (

        <React.Fragment>
            <section className="w-full pt-14 md:pt-28 ">
            <div className='w-full flex justify-center pb-4 md:pb-16'>
                    <Headline>
                        {props.language === "en" ? <span>Our Partners</span> : <span>Unsere Partner</span>}
                    </Headline>
                </div>
                <div class="max-w-screen-xl md:w-1/2 px-4 pl-4 pr-4 pb-8 mx-auto lg:pb-16 pt-16">
                <div className="flex flex-wrap gap-2 text-gray-500 sm:gap-32 justify-center md:justify-center lg:justify-center dark:text-gray-400">
                    {sponsors &&
                        sponsors.map((sponsor, index) => (
                          
                            (sponsor.name=="01_logo_landeshauptstadt" || sponsor.name=="03_logo_sparkasseHannover") &&
                            
                        <span key={index} className="flex items-center mx-auto mb-16 w-full md:w-2/6 "
                                        ref={(el) => (sponsorsRef.current[index] = el)}>
                            <img className="mx-auto" src={getSanityImageUrl(sponsor.logo.asset).height(60).url()} alt={`Sponsor ${index + 1}`} />
                        </span>
                      
                        ))}
                </div>
                </div>


                <div className='w-full flex justify-center pb-4 md:pb-16'>
                    <Headline>
                        {props.language === "en" ? <span>Our Supporters</span> : <span>Unsere Förderer</span>}
                    </Headline>
                </div>
                <div class="max-w-screen-xl px-4 pl-16 pr-16 pb-8 mx-auto lg:pb-16 pt-16">
                <div className="flex flex-wrap  gap-16 text-gray-500 sm:gap-32 justify-center md:justify-center lg:justify-center dark:text-gray-400">
                    {sponsors &&
                        sponsors.map((sponsor, index) => (
                          
                            sponsor.name!="01_logo_landeshauptstadt" && sponsor.name!="03_logo_sparkasseHannover" &&
                        <span key={index} className="flex items-center mr-auto mb-2 w-full md:w-1/6"
                                        ref={(el) => (sponsorsRef.current[index] = el)}>
                            <img className="mx-auto" src={getSanityImageUrl(sponsor.logo.asset).height(60).url()} alt={`Sponsor ${index + 1}`} />
                        </span>
                      
                        ))}
                </div>
                    <div className='w-full text-center text-gray font-roboto text-lg mt-32 pb-16'>Schirmherrschaft:<br/> <span className='font-bold'>Stephan Weil</span> <br/>Ministerpräsident des Landes Niedersachsen</div>
                </div>
            </section>
        </React.Fragment>

    )

}

export default Sponsors;