import React, {} from 'react';
import Headline from './headline';
import Paragraph from './paragraph';
import Carousel from '../components/carousel';
import CarouselSolo from '../components/carouselSolo';
import CarouselDouble from '../components/carouselDouble';
import { PortableText } from '@portabletext/react';



const ChorCarousel = (props) => {

  function GetCarousel(){
    console.log("CAROUSEL TYPE:"+props.type);
    switch(props.type)
    {
      case 0:    return <Carousel choere={props.choere} screenWidth={props.screenWidth}/>;
      case 1:     return <CarouselSolo chorindex={props.chorindex} choere={props.choere} screenWidth={props.screenWidth} />;
      case 2:    return <CarouselDouble choere={props.choere} screenWidth={props.screenWidth}/>;
    }
 
  }

  return (
    <div className={"items-center flex flex-col "+props.background}>
        <Headline className="pt-6 pb-3 md:pt-12 md:pb-6">
          {props.headline && props.headline}
        </Headline>
        <Paragraph className="md:px-64 text-center">
          {props.text && <PortableText value={props.text} />}
        </Paragraph>
        <div className="container px-5 pt-12 md:pt-24 mx-auto">
        {GetCarousel()}
        </div>
    </div>
  )
}

export default ChorCarousel