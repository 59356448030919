
  
  export const textFormat = {
    marks: {
      // Ex. 1: custom renderer for the em / italics decorator
      em: ({children}) => <em className="text-gray-600 font-semibold">{children}</em>,
      
      link: ({value, children}) => {
        const target = (value?.href || '').startsWith('http') ? '_self' : undefined
        return (
          <a className="link link-hover text-gray-600 font-semibold" href={value?.href} target={target} rel={target === '_self' && 'noindex nofollow'}>
            {children}
          </a>
        )
      },
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({children}) => <h1 className="text-2xl">{children}</h1>,
      
      blockquote: ({children}) => <blockquote className="border-l-purple-500">{children}</blockquote>,
  
      // Ex. 2: rendering custom styles
      customHeading: ({children}) => (
        <h2 className="text-lg text-primary text-purple-700">{children}</h2>
      ),
      hervorgehoben: ({children}) =>(
        <p className="min-h-6 text-lg font-roboto font-bold mt-2 mb-2">{children}</p>
      ),
      normal: ({children}) =>(
        <p className="min-h-6 text-lg font-roboto font-normal">{children}</p>
      )
    }
    /*
    block: ({ node, children }) => {
      console.log("NODESTYLE:"+node);
      console.log(node);
      
      if (node.style === 'hervorgehoben') {
        return <p className="min-h-6 text-lg font-roboto font-bold">{children}</p>;
      }
      
      // Default style for 'normal'
      return <p className="min-h-6 text-lg font-roboto font-normal">{children}</p>;
    },*/
  };
