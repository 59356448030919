import React,{useEffect,useState} from 'react';
import {useContent} from '../hooks/content-context';
import sanityClient from "../client.js";
import { GetVeranstaltungOrtLink,GetVeranstaltungBeschreibung,GetVeranstaltungTicketLink, GetVeranstaltungDatum,GetVeranstaltungOrt,GetVeranstaltungFormat, GetVeranstaltungFormatLink, GetVeranstaltungName, GetVeranstaltungDatumFormatA, GetVeranstaltungZeit, GetVeranstaltungLink, GetVeranstaltungChoere } from "../helperFunctions/helperFunctionsVeranstaltung";
export const TwoDigits=(digitString)=>
    {
        return digitString.toString().padStart(2, '0');
    }
export const FormatDateTime =(datetime)=>
    {
        return TwoDigits(datetime.getDate())  + "." + TwoDigits(datetime.getMonth()+1) + "." + datetime.getFullYear() + " " +
        TwoDigits(datetime.getHours()) + ":" + TwoDigits(datetime.getMinutes());
    }

function Content(props) {

    const {dispatchContent} = useContent();
    const [choere,setChoere] =useState(0);
    const [choereRegional,setChoereRegional] =useState(0);
    const [specialGuests,setSpecialGuests] =useState(0);
    const [team,setTeam] =useState(0);
    const [sponsors,setSponsors] =useState(0);
    const [veranstaltungsorte,setVeranstaltungsorte] =useState(0);
    const [footer,setFooter] =useState(0);
    
    function addContent(contentData){
        dispatchContent({ type:"addData", contentData });
      }

    function FetchTextbausteine(){
    sanityClient.fetch(
        /* groq */`*[_type == "Textbausteine"] {
        _id,
        introHeadline,
        introText,
        veranstaltungenHeadline,
        veranstaltungen,
        InternationaleChoereHeadline,
        InternationaleChoereText,
        LokaleChoereHeadline,
        LokaleChoereText,
        ProjekteHeadline,
        ProjekteText
    }`).then((data) => {

       // setVeranstaltungsorte(data);
        addContent({"textbausteine":data});
        }).catch(console.error);
    }

    function FetchFormate(){
        sanityClient.fetch(
            /* groq */`*[_type == "Format"] {
            _id,
            link,
            name,
            beschreibung,
            formatBild
        }`).then((data) => {
    
           // setVeranstaltungsorte(data);
            addContent({"formate":data});
            }).catch(console.error);
        }
    

function FetchVeranstaltungsorte(){
    sanityClient.fetch(
        /* groq */`*[_type == "Veranstaltungsort"] | order(identifier){
        _id,
        identifier,
        link,
        name,
        koordinaten,
        bild,
        beschreibung
    }`).then((data) => {

        setVeranstaltungsorte(data);
       addContent({"veranstaltungsorte":data});
      }).catch(console.error);
}

function FetchChoere(){
    sanityClient.fetch(
        /* groq */`*[_type == "Chor"] | order(orderRank){
        _id,
        identifier,
        link,
        name,
        land,
        chorurl,
        gruppenbild,
        beschreibung
    }`).then((data) => {
        setChoere(data);
        addContent({"choere":data});
      }).catch(console.error);    
}

function FetchChoereRegional(){
    sanityClient.fetch(
        /* groq */`*[_type == "ChorRegional"] | order(orderRank){
        _id,
        identifier,
        link,
        name,
        land,
        chorurl,
        gruppenbild,
        beschreibung
    }`).then((data) => {
        setChoereRegional(data);
        addContent({"choereRegional":data});
      }).catch(console.error);    
}

function FetchFooter(){
    sanityClient.fetch(
        /* groq */`*[_type == "footer"] | order(identifier){
        _id,
        link,
        name,
        bild,
        text,
        files,
        'downloads': files[]{
            'file': asset->url,
            'name': filename,
            'description': description
          }

    }`).then((data) => {
        setFooter(data);
        addContent({"footer":data});
      }).catch(console.error);    
}
// 'files': files[].asset-&gt;url
function FetchSpecialGuests(){
    sanityClient.fetch(
        /* groq */`*[_type == "SpecialGuest"] | order(orderRank){
        _id,
        identifier,
        link,
        name,
        land,
        url,
        bild,
        beschreibung
    }`).then((data) => {
        setSpecialGuests(data);
        addContent({"specialGuests":data});
      }).catch(console.error);    

}

function FetchTeam(){
    sanityClient.fetch(
        /* groq */`*[_type == "team"] | order(orderRank){
        _id,
        name,
        mail,
        position,
        bild,
        beschreibung
    }`).then((data) => {
        setTeam(data);
        addContent({"team":data});
      }).catch(console.error);    
}

function FetchSponsors(){
    sanityClient.fetch(
        /* groq */`*[_type == "sponsors"] | order(orderRank){
        _id,
        name,
        sponsorlink,
        logo
    }`).then((data) => {
        setSponsors(data);
        addContent({"sponsors":data});
      }).catch(console.error);    
}

function FetchImpressionen(){
    sanityClient.fetch(
        /* groq */`*[_type == "impressionen"] | order(orderRank){
        _id,
        name,
        bild
    }`).then((data) => {
        setTeam(data);
        addContent({"impressionen":data});
      }).catch(console.error);    
}

function FetchVeranstaltungen()
{
    sanityClient.fetch(
        /* groq */`*[_type == "Veranstaltung"] | order(termin, region){
        _id,
        identifier,
        link,
        name,
        format->,
        termin,
        bild,
        beschreibung,
        choere,
        veranstaltungsort,
        ticketlink,
        stadt,
        region,
        outdoor
    }`).then((data) => {

        /**** 
        console.log(`++++++++ ALLE TICKET TERMINE +++++++++++`);
        const filteredData = data.filter(veranstaltung => veranstaltung.ticketlink !== "");
        if(filteredData)
        filteredData.forEach(veranstaltung => {
            console.log(`termin: ${FormatDateTime(new Date(veranstaltung.termin))}`);
            //console.log(`veranstaltungsort: ${veranstaltung.veranstaltungsort[0].name[0].value}`); 
            console.log(`Name DE: ${veranstaltung.format.name[0].value}`);
            if(veranstaltung.format.name[1])console.log(`Name EN: ${veranstaltung.format.name[1].value}`);
            console.log(`format: ${veranstaltung.format.name[0].value}`);
            //console.log(`beschreibung: ${GetVeranstaltungBeschreibung(veranstaltung,"de")}`);
           // console.log(`beschreibung en: ${GetVeranstaltungBeschreibung(veranstaltung,"en")}`);
           if(veranstaltung.choere)
            veranstaltung.choere.forEach(chor => {
        console.log("Chor Ref:"+chor._ref);
        if(chor.name)
        {
                if(chor.name[0])
                console.log(`Chor Name DE: ${chor.name[0].value}`);
                if(chor.name[1])
                console.log(`Chor Name EN: ${chor.name[1].value}`);
            }
            });
            console.log("------------");
        });
        console.log(`++++++++ +++++++++++++++++++ +++++++++++`);

         */
      var programmpunkte=data;
  
        for(let i=0;i<programmpunkte.length;i++)
        {
        if(programmpunkte[i].choere)
        {
          programmpunkte[i].choere.forEach((chorReference, chorIndex)=>{
              choere.forEach(chor=> {
                  if(chor._id===chorReference._ref)
                  {
                      programmpunkte[i].choere[chorIndex]=chor;
                  }
              })
  
          });

          programmpunkte[i].choere.forEach((chorReference, chorIndex)=>{
            choereRegional.forEach(chor=> {
                if(chor._id===chorReference._ref)
                {
                    programmpunkte[i].choere[chorIndex]=chor;
                }
            })

        });

        programmpunkte[i].choere.forEach((chorReference, chorIndex)=>{
            specialGuests.forEach(chor=> {
                if(chor._id===chorReference._ref)
                {
                    programmpunkte[i].choere[chorIndex]=chor;
                }
            })

        });

        }
          programmpunkte[i].veranstaltungsort.forEach((ortReference, ortIndex)=>{
              veranstaltungsorte.forEach(ort=>
              {

                  if(ort._id===ortReference._ref)
                  {
                      programmpunkte[i].veranstaltungsort[ortIndex]=ort;
                  }
              })
  
          })
      };
      addContent({"veranstaltungen":programmpunkte});
  
      }).catch(console.error);
  
}

useEffect(() => {
    FetchVeranstaltungsorte();
    FetchChoere();
    FetchChoereRegional();
    FetchSpecialGuests();
    FetchTeam();
    FetchSponsors();
    FetchTextbausteine();
    FetchFormate();
    FetchFooter();
    FetchImpressionen();
}, [dispatchContent]);

useEffect(() => {
  if(veranstaltungsorte && choere && choereRegional && specialGuests)
  {
    FetchVeranstaltungen();
  }
}, [choere,choereRegional,veranstaltungsorte]);
  
    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default Content;