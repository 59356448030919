import React, { useState, useContext,useRef,useEffect } from 'react';
import { GetChorName, GetChorImage, GetLink, GetChorLand } from "../helperFunctions/helperFunctionsChor";
import { Link } from "react-router-dom";
import { LanguageContext } from '../hooks/languageContext';
import Paragraph from '../elements/paragraph';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Carousel = ({ choere, screenWidth, chorindex }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [language, setLanguage] = useContext(LanguageContext);
  const choereRef = useRef([]);

  useEffect(() => {
    if(choere)
    {
        gsap.set(choereRef.current, { scale: 0 });
    
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: choereRef.current[0],
            start: 'top 100%',
            end: 'top 80%',
            scrub: true,
            markers: false, // Remove this in production
          },
        });
    
        // Animation for the first set of buttons (scale from 0 to 1)
        tl.to(choereRef.current, {
          scale: 1,
          stagger: 0.5, // Adjust the stagger effect as needed
        });
        
        setTimeout(()=>{ScrollTrigger.refresh(true);}, 100);
      }
      }, [choereRef,choere]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex === choere.length - getNrChoereToShow() ? prevIndex : prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? prevIndex : prevIndex - 1
    );
  };

  const getNrChoereToShow = () => {
    let nrChoere = 1;
    if (screenWidth > 640) nrChoere = 2;
    if (screenWidth > 1024) nrChoere = 3;
    if (choere.length === 1) {
      nrChoere = 1;
    }
    
    return nrChoere;
  }

  const getChorLayout = () => {
    let classname = "w-full px-6 h-full text-center";
    if (screenWidth > 640) classname = "w-1/2 px-6 h-full text-center";
    if (screenWidth > 1024) classname = "w-1/3 px-6 h-full text-center";
    if (choere.length === 1) classname = "w-full px-6 h-full text-center";

    return classname;
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex overflow-hidden">

        {choere.slice(chorindex, chorindex + 1).map((chor, index) => (
          <div
            key={index}
            className="w-full px-6 h-full text-center"

          >
            <Link to={"../choere/" + GetLink(chor)}>
              <img ref={(el) => (choereRef.current[index] = el)} src={GetChorImage(chor, 320, 320)} alt={`Image ${index}`} className="w-80 h-80 mb-2 md:mb-8 object-cover object-center mask mask-circle bg-gray-100" />
            </Link>
            <Link to={"../choere/" + GetLink(chor)}>
              <Paragraph>{GetChorName(chor, language)}</Paragraph>
            </Link>
            <h2 className="text-gray font-medium title-font tracking-wider text-lg">{GetChorLand(chor, language)}</h2>
            <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-2 mb-2 md:mt-6 md:mb-4"></span>

          </div>
        ))}


      </div>




    </div>
  );
};

export default Carousel;