import React, { useState, useEffect } from 'react'

const Switcher6 = (props) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    
    setIsChecked(!isChecked);
    props.onSelectLanguage(isChecked? "de":"en");
    
   
  }

  useEffect(() => {
    if(props.language==="de")
      setIsChecked(false)
    if(props.language==="en")
      setIsChecked(true)    
  }, [props.language]);

  return (
    <>
      <label className='flex cursor-pointer select-none items-center w-full'>
        <div className='relative'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
            className='sr-only'
          />
          <div className='box bg-gray block h-5 w-8 rounded-full'></div>
          <div
            className={`dot absolute bg-white top-1 flex h-3 w-3 items-center justify-center rounded-full transition  ${
              isChecked ? 'right-1' : 'left-1'
            }`}
          ></div>
        </div>
      </label>
    </>
  )
}

export default Switcher6