import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";


    export const GetIntroHeadline=(textbausteine, language)=>
    {
        let text=textbausteine.introHeadline[0].value;
        if(language==="en")
            {
            text=textbausteine.introHeadline[1].value;
        }
        return text;
    }


    export const GetIntroText=(textbausteine, language)=>
    {
        let text=textbausteine.introText.de;
        if(language==="en")
            text=textbausteine.introText.en;
        return text;
    }


    export const GetInternationalChoirHeadline=(textbausteine, language)=>
    {

        let text=textbausteine.InternationaleChoereHeadline[0].value;
        if(language==="en")
            text=textbausteine.InternationaleChoereHeadline[1].value;
        return text;
    }


    export const GetInternationaleChoirText=(textbausteine, language)=>
    {
        let text=textbausteine.InternationaleChoereText.de;
        if(language==="en")
            text=textbausteine.InternationaleChoereText.en;
        return text;
    }


    export const GetLocalChoirHeadline=(textbausteine, language)=>
    {
        let text=textbausteine.LokaleChoereHeadline[0].value;
        if(language==="en")
            text=textbausteine.LokaleChoereHeadline[1].value;
        return text;
    }

    export const GetLocalChoirText=(textbausteine, language)=>
    {
        let text=textbausteine.LokaleChoereText.de;
        if(language==="en")
            text=textbausteine.LokaleChoereText.en;
        return text;
    }

    export const GetProjekteHeadline=(textbausteine, language)=>
    {
        let text=textbausteine.ProjekteHeadline[0].value;
        if(language==="en")
            text=textbausteine.ProjekteHeadline[1].value;
        return text;
    }

    export const GetProjekteText=(textbausteine, language)=>
    {
        let text=textbausteine.ProjekteText.de;
        if(language==="en")
            text=textbausteine.ProjekteText.en;
        return text;
    }

    export const GetVeranstaltungenHeadline=(textbausteine, language)=>
    {
        let text=textbausteine.veranstaltungenHeadline[0].value;
        if(language==="en")
            text=textbausteine.veranstaltungenHeadline[1].value;
        return text;
    }

    export const GetVeranstaltungenText=(textbausteine, language)=>
    {
        let text=textbausteine.veranstaltungen.de;
        if(language==="en")
            text=textbausteine.veranstaltungen.en;
        return text; 
    }


    