import React, {useEffect, useState, useContext} from 'react';

import { useParams,Link,useNavigate  } from 'react-router-dom';
import {useContent} from '../hooks/content-context';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import sanityClient from "../client.js";
import NavigationElement from '../elements/navigationElement';
import {GetFooterText, GetFooterName,GetFiles} from "../helperFunctions/helperFunctionsFooter.js"
import Header from '../components/header';
import Footer from '../components/footer';
import Headline from '../elements/headline.js';
import Paragraph from '../elements/paragraph.js';
import Skyline from '../grafiken/skyline.png';
import Newsletter from './newsletter.js';
import BlockContent from "@sanity/block-content-to-react";
import {GetImpressionImage, GetImpressionName,GetImageLink} from "../helperFunctions/helperFunctionsImpressionen.js";
import Impression from './impression';
import abschluss from '../grafiken/Abschlusskonzert.mp4';
import { LanguageContext } from '../hooks/languageContext';

const Impressionen = ({props}) => {

    const {id} = useParams();
    const {state: {impressionen}} = useContent();
    const [language, setLanguage] = useContext(LanguageContext);


    function ButtonLink({ to,className, children }) {
        return <Link to={to}><button className={className}>{children}</button></Link>;
      }
      useEffect(()=>{
//console.log("IMPRESSIONEN");
//console.log(impressionen)
      },[impressionen])
      
      useEffect(()=>{
          window.scrollTo(0, 0);
      },[])






      
    
 
  
  const components = {
    marks: {
      // Ex. 1: custom renderer for the em / italics decorator
      em: ({children}) => <em className="text-gray-600 font-semibold">{children}</em>,
      
      link: ({value, children}) => {
        const target = (value?.href || '').startsWith('http') ? '_self' : undefined
        return (
          <a className="link link-hover text-gray-600 font-semibold" href={value?.href} target={target} rel={target === '_self' && 'noindex nofollow'}>
            {children}
          </a>
        )
      },
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({children}) => <h1 className="text-2xl">{children}</h1>,
      
      blockquote: ({children}) => <blockquote className="border-l-purple-500">{children}</blockquote>,
  
      // Ex. 2: rendering custom styles
      customHeading: ({children}) => (
        <h2 className="text-lg text-primary text-purple-700">{children}</h2>
      ),
      hervorgehoben: ({children}) =>(
        <p className="min-h-6  mt-1 text-lg font-roboto font-bold">{children}</p>
      ),
      normal: ({children}) =>(
        <p className="min-h-6 text-base font-roboto font-normal">{children}</p>
      )
    }
    /*
    block: ({ node, children }) => {
      console.log("NODESTYLE:"+node);
      console.log(node);
      
      if (node.style === 'hervorgehoben') {
        return <p className="min-h-6 text-lg font-roboto font-bold">{children}</p>;
      }
      
      // Default style for 'normal'
      return <p className="min-h-6 text-lg font-roboto font-normal">{children}</p>;
    },*/
  };

  const serializers = {
    types: {
      block: (props) => {
        const style = props.node.style || "normal";
  
        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "");
          return React.createElement(
            `h${level}`,
            { className: "text-lg" },
            props.children
          );
        }
  
        return style === "blockquote" ? (
          <blockquote>– {props.children}</blockquote>
        ) : (
          <p>{props.children}</p>
        );
      },
      code: (props) =>
        console.log("code block", props) || (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        ),
      youtube: (props) => <pre>{JSON.stringify(props, null, 2)}</pre>
    },
    list: (props) =>
      console.log("list", props) ||
      (props.type === "bullet" ? (
        <ul className="list-disc list-outside">{props.children}</ul>
      ) : (
        <ol className="list-decimal list-outside">{props.children}</ol>
      )),
    listItem: (props) =>
      console.log("list", props) ||
      (props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>{props.children}</li>
      )),
    marks: {
      strong: (props) =>
        console.log("strong", props) || <strong>{props.children}</strong>,
      em: (props) => console.log("em", props) || <em>{props.children}</em>,
      code: (props) => console.log("code", props) || <code>{props.children}</code>
    }
  };

  


    return (  

        <React.Fragment>

            <Header/>
            

            <div className="relative w-full h-52 flex items-center justify-center bg-yellow">

  <img
    src={Skyline} 
    alt="Background Image"
    className="absolute inset-0 w-full h-full object-cover z-0"
  />


  
</div>

     <div>       
     
    
     
    <div className=" bg-white  w-full h-full sticky z-20 top-0">
    <div className="h-full text-center text-lg px-0 md:px-10 lg:px-20 py-4 text-gray font-normal font-roboto justify-center flex ">
      <div className="relative w-full md:w-3/4 z-10  h-full text-left text-3xl pt-3 text-gray font-roboto font-bold break-words">
        <span className='px-6'>
      Impressionen
      </span>
    </div>
  </div>
      
  <div className="w-full mt-20 bg-gray-100 px-10 pt-10">

  <div className="flex flex-col items-center w-full mb-36">
  <video width="640" height="360" src={abschluss} type="video/mp4" controls>
    Your browser does not support the video tag.
  </video>
  <div className="px-6 mt-8">
    <h1 className="font-normal font-roboto text-sm text-center mb-1">Abschlusskonzert Finale / Patrick Huss / Lutz Krajenski / Will Todd / Alle Chöre und Publikum</h1>
  </div>
</div>
               <div className="container mx-auto">
                   <div role="list" aria-label="Impressions" className="lg:flex md:flex sm:flex items-start xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">
                       
        

                      {impressionen && impressionen.map((impression,index) => (
                                                      
                           <Impression language={language} image={GetImpressionImage(impression,450,450)} name={GetImpressionName(impression,language)} link={GetImageLink(impression)}/>
                     
                      ))
                      } 
                    
                     
                      
                   </div>
               </div>
           </div>
      
    </div>

   <div className='mb-12'></div>
 
</div>
      <Footer/>
        </React.Fragment>
    );
}

export default Impressionen;