import React, {useRef,useEffect} from 'react';
import { Link } from 'react-router-dom';
import SpecialsBtn from '../grafiken/specials.png';
import KalenderBtn from '../grafiken/kalender.png';
import AlleChoereBtn from '../grafiken/allechoere.png';
import KalenderBtn_en from '../grafiken/kalender_en.png';
import AlleChoereBtn_en from '../grafiken/allechoere_en.png';
import FavoritenBtn_en from '../grafiken/favoriten_en.png';
import FavoritenBtn from '../grafiken/favoriten.png';

import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';

const ButtonBar=(props)=>{

  function ButtonLink({ to, className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

  const buttonARef =useRef(null);
  const buttonBRef =useRef(null);
  const buttonCRef =useRef(null);
  const buttonDRef =useRef(null);
  
      useEffect(()=>{
       
      // Check if ScrollTrigger is available
      if (ScrollTrigger && buttonARef.current) {
        gsap.set([buttonARef.current, buttonBRef.current, buttonCRef.current, buttonDRef.current], { scale: 0 });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: buttonARef.current,
          start: 'top bottom',
          end: 'top 70%',
          scrub: true,
          markers: false, // Remove this in production
        },
      });

      // Animation for the first set of buttons (scale from 0 to 1)
      tl.to([buttonARef.current, buttonBRef.current, buttonCRef.current, buttonDRef.current], {
        scale: 1,
        stagger: 0.5, // Adjust the stagger effect as needed
      });
      }
    },[buttonARef.current])

 
  return (
    <React.Fragment>
           <div className="flex flex-wrap px-6 pt-4 pb-24 w-full md:w-5/6 lg:w-4/6 xl:w-3/6">

<div className=" w-2/4 md:w-1/4  flex justify-center">
  <ButtonLink to="../veranstaltungen/" className='h-28 md:h-28 lg:h-40'>
    {props.language==="en"?
    <img ref={buttonARef} src={KalenderBtn_en} alt="Kalender" className='h-full'/>
    :
    <img ref={buttonARef} src={KalenderBtn} alt="Kalender" className='h-full'/>

  }
  </ButtonLink>
</div>

<div className="w-2/4 md:w-1/4 flex justify-center">
  <ButtonLink to="../likedveranstaltungen/" className='h-28 md:h-28 lg:h-40'>
  {props.language==="en"?
 <img ref={buttonBRef} src={FavoritenBtn_en} alt="Favoriten" className='h-full'/>
:
 <img ref={buttonBRef} src={FavoritenBtn} alt="Favoriten" className='h-full'/>
 }
  </ButtonLink>
</div>

<div className="w-2/4 mt-2 md:mt-0 md:w-1/4 flex justify-center">
  <ButtonLink to="../choere/littlesingersofarmenia" className='h-28 md:h-28 lg:h-40'>
  {props.language==="en"?
  <img ref={buttonCRef} src={AlleChoereBtn_en} alt="Alle Choere" className='h-full'/>
:
<img ref={buttonCRef} src={AlleChoereBtn} alt="Alle Choere" className='h-full'/>
}
  </ButtonLink>


</div>

<div className="w-2/4 mt-2 md:mt-0 md:w-1/4 flex justify-center">
  <ButtonLink to="../choere/willtoddb" className='h-28 md:h-28 lg:h-40'>

    <img ref={buttonDRef} src={SpecialsBtn} alt="Specials" className='h-full'/>
  </ButtonLink>
</div>

</div>
    </React.Fragment>
  )
}

export default ButtonBar