import React, { useEffect, useState, useContext } from 'react';
import { useContent } from '../hooks/content-context';
import LikeButton from '../elements/likeButton';
import { GetVeranstaltungOrtLink, GetVeranstaltungOrt,GetVeranstaltungChoere, GetVeranstaltungDatum, GetVeranstaltungName, GetVeranstaltungDatumFormatA, GetVeranstaltungZeit, GetVeranstaltungLink,GetVeranstaltungTicketLink,GetVeranstaltungFormat, GetVeranstaltungFormatLink, IsVeranstaltungCity } from "../helperFunctions/helperFunctionsVeranstaltung";
import VeranstaltungTermin from '../elements/VeranstaltungTermin';
import { useParams,Link,useNavigate  } from 'react-router-dom';

function VeranstaltungenListe(props) {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); 


  function ButtonLink({ to,className, children }) {
    return <Link to={to}><button className={className}>{children}</button></Link>;
  }

  const { state: { veranstaltungen } } = useContent();

  function isSameDate(veranstaltung,index){
    
    if(index===0) return false;
    if(GetVeranstaltungDatumFormatA(props.veranstaltungen[index - 1]) === GetVeranstaltungDatumFormatA(veranstaltung))
      return true;
    else return false;
  }

  return (
    <React.Fragment>
      <div className={props.width + " " + props.height + " card bg-white max-h-max  shadow-none md:shadow-xl z-20"}>

        <div className="card-body px-1 md:px-5 h-full">
          <h2 className="card-title font-roboto font-bold text-gray"></h2>
          <div className='h-full overflow-y-auto pointer-events-auto'>

            <table className="table  table-sm px-1 md:px-3 ">

              {props.veranstaltungen && props.veranstaltungen.map((veranstaltung, index) => (
                 <VeranstaltungTermin screenWidth={screenWidth} veranstaltung={veranstaltung} name={GetVeranstaltungName(veranstaltung,props.language, true)} ort={GetVeranstaltungOrt(veranstaltung,props.language)} format={GetVeranstaltungFormat(veranstaltung)} formatLink={GetVeranstaltungFormatLink(veranstaltung)} choere={GetVeranstaltungChoere(veranstaltung)} zeit={GetVeranstaltungZeit(veranstaltung)} id={veranstaltung._id} datum={GetVeranstaltungDatumFormatA(veranstaltung)} newDate={isSameDate(veranstaltung,index)} link={GetVeranstaltungLink(veranstaltung)} ticketlink={GetVeranstaltungTicketLink(veranstaltung)} stadt={IsVeranstaltungCity(veranstaltung)}/>
              ))}

            </table>

          </div>
         
        </div>
      </div>
    </React.Fragment>
  );

}


export default VeranstaltungenListe;