import React, {useEffect, useState} from 'react';
export const LanguageContext = React.createContext();


function LanguageProvider({children}){

    const [language, setLanguage] = useState(0);
  
      useEffect(()=>{
        setLanguage("de");
      },[])

      return <LanguageContext.Provider value={[language, setLanguage]}>{children}</LanguageContext.Provider>
  
  

}

function useLanguage() {
    const context = React.useContext(LanguageContext)
    if (context === undefined) {
      throw new Error('useLanguage must be used within a LanguageProvider')
    }
    return context
  }
  
  export {LanguageProvider, useLanguage}