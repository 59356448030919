import React, { } from 'react';

function Headline(props) {


    return (
                <div className={"px-4 break-words text-center font-robotserif font-bold italic text-2xl w-full md:text-5xl text-gray  "+props.className}>
                  <span>{props.children}</span>
                </div>
    )
}

export default Headline;