import imageUrlBuilder from '@sanity/image-url';
import sanityClient from "../client.js";
const sanityImageBuilder = imageUrlBuilder(sanityClient);

export const getSanityImageUrl=(source)=> {
        return sanityImageBuilder.image(source)
      }

      export const GetChorDescription=(chor,language)=>{
        let text="More infos coming soon.";
        if(chor.beschreibung)
        {
          if(language === "de")
            text=chor.beschreibung.de;
          if(language==="en")
            text=chor.beschreibung.en;

        }
        return text;
      }
  
      export const GetChorName=(chor, language)=>
      {
        let text="undefined";
        if(chor.name)
        {
          text=chor.name[0].value;
          if(language==="en" && chor.name[1])
          {
            text=chor.name[1].value;
          }
        }
        return text;
      }


      export const GetChorLand=(chor, language)=>
      {
        let text="undefined";
        if(chor.land)
        {
          text=chor.land[0].value;
          if(language==="en" && chor.land[1])
          {
            text=chor.land[1].value;
          }
        }
        return text;
      }

      export const GetChorLink=(chor)=>
      {
        if(chor.chorurl)return chor.chorurl;
        else if(chor.url)return chor.url;
        else return null;
      }
  
      export const GetChorImage=(chor, width, height)=>
      {

        if(chor.gruppenbild)
        {
          return getSanityImageUrl(chor.gruppenbild.asset)
          .width(width).height(height).focalPoint(chor.gruppenbild.hotspot.x, chor.gruppenbild.hotspot.y)
          .fit("crop").crop("focalpoint").url();
        }
        else if (chor.bild)
        {
          return getSanityImageUrl(chor.bild.asset)
          .width(width).height(height).focalPoint(chor.bild.hotspot.x, chor.bild.hotspot.y)
          .fit("crop").crop("focalpoint").url();
        }
        else return null;
      }

      export const GetLink=(chor)=>{
        return chor.link.current;
    }