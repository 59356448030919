import React, { useState, useEffect, useContext } from 'react';
import { useCookieContext } from '../hooks/cookie-context';
import LanguageSwitch from '../elements/languageSwitch';
import { LanguageContext } from '../hooks/languageContext';

const CookieConsentBanner = () => {
    const [isConsentSet, setConsentSet] = useState(false);
  const { showBanner, reShowBanner, setCookieConsent, consent} = useCookieContext();
  const [language, setLanguage] = useContext(LanguageContext);
  useEffect(() => {
    const hasConsent = localStorage.getItem('chorfestival2024Cookie');
    if (hasConsent=="true") {
        setConsentSet(true);
        setCookieConsent("true");
    }
        
  }, []);

  useEffect(() => {

    if(showBanner!=null)
    {
        setConsentSet(false);
    }
    
  }, [showBanner]);

  const handleAccept = () => {
    localStorage.setItem('chorfestival2024Cookie', 'true');
    setConsentSet(true);
    setCookieConsent("true");
    //window.location.reload();
  };

  const handleDecline = () => {

    localStorage.setItem('chorfestival2024Cookie', 'false');
    setConsentSet(true);
    setCookieConsent("false");
    //window.location.reload();
  };

  return (
    <React.Fragment>
    {!isConsentSet &&
        <div className="fixed bottom-0 left-0 right-0 font-roboto border-gray border-solid border-t-2 bg-white text-gray p-4 flex items-center flex-wrap justify-between z-50">
        
        <div className='flex-1 justify-center flex'>
        <LanguageSwitch/>
        </div>

        {language=="de"?
        <p className='w-full mb-2 mt-8 mx-8 min-h-32'>
        Diese Website verwendet Cookies, um Ihnen eine optimale Nutzererfahrung zu bieten. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. Wir verwenden Cookies nur für die Inhaltsverwaltung mit Sanity und für Kartendienste von Mapbox. Beachten Sie, dass bei Ablehnung die Kartennutzung deaktiviert wird, was die Nutzung einschränkt.
        </p>
        :
        <p className='w-full my-2 mt-8 mx-8 min-h-32'>
        This website uses cookies to provide you with the best user experience. By using our website, you agree to the use of cookies. We use cookies only for content management with Sanity and Mapbox map services. Please note that if you decline, card usage will be disabled, limiting your overall experience.
        </p>
}
        <div className="space-x-6 mb-4 mt-8 mx-4 w-full h-16 flex justify-center items-center">
          <button className="bg-yellow text-gray px-4 py-2 h-16 w-32 font-bold rounded text-xl" onClick={handleAccept}>
            Accept
          </button>
          <button className="bg-black text-white px-4 py-2 font-light rounded h-12" onClick={handleDecline}>
            Decline
          </button>
        </div>
      </div>
    }
    </React.Fragment>
  );
};

export default CookieConsentBanner;